import React from 'react';
import { useTranslation } from 'react-i18next';

import download from '@/assets/download.svg';

import { useGuestTranslationDownload } from '../../hooks/useGuestTranslationDownload';

/**
 * プロパティ
 */
type Props = {
  // 表示メニューを閉じる処理
  onCloseMenu: () => void;
};

/**
 * ゲスト画面 CSVダウンロードメニュー コンポーネント
 */
export const GuestCSVDownloadContainer = React.memo(
  ({ onCloseMenu }: Props) => {
    const { t } = useTranslation();
    const { onCsvDownloadButton } = useGuestTranslationDownload();

    return (
      <button
        type="button"
        onClick={() => {
          onCloseMenu();
          onCsvDownloadButton();
        }}
        className="guest-05"
        data-testid="share-csv-download"
      >
        <img src={download} alt="download" />
        {t('shareGuest.通訳履歴をダウンロード')}
      </button>
    );
  },
);
