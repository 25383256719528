// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationMessageOverlayView } from '@/components/Elements/MessageOverlayView';
import { TRANSLATION_LIST_VIEW_TYPE } from '@/constants';
import {
  useFirestoreLogout,
  useViewConfigCollection,
} from '@/features/firestore';
import { ShareDialogForHost } from '@/features/share';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

import { useShareCheck } from '../../hooks/share/useShareCheck';
import { useTranslationShareView } from '../../hooks/share/useTranslationShareView';

import { ShareInfoErrorDialog } from './ShareInfoErrorDialog';
import { SharePauseCompleteDialog } from './SharePauseCompleteDialog';
import { ShareResumeCompleteDialog } from './ShareResumeCompleteDialog';

/**
 * プロパティ
 */
type ShareTranslationViewProps = {
  // 共有の一時停止/再開でSTT状態を同期させる際に実行したい関数
  syncSttWithShareStatus: () => void;
};

/**
 * プロパティ
 */
type Props = {
  // Firestoreトークン
  firestoreToken: string;
  // 共有一時停止完了ダイアログ表示フラグ
  isOpenSharePauseCompleteDialog: boolean;
  // 共有再開完了ダイアログ表示フラグ
  isOpenShareResumeCompleteDialog: boolean;
  // 共有画面の状態が共有中か否か
  isShareActive: boolean;
} & ShareTranslationViewProps;

/**
 * Firestore監視用ビュー
 * ※共有中の場合のみ読み込まれる
 */
export const FirestoreMonitoringView = React.memo(
  ({
    firestoreToken,
    isOpenSharePauseCompleteDialog,
    isOpenShareResumeCompleteDialog,
    isShareActive,
    syncSttWithShareStatus,
  }: Props) => {
    const {
      onChangeViewConfig,
      firestoreConnectionError,
      onClickOKSharePauseCompleteDialog,
      onClickOKShareRestartCompleteDialog,
    } = useTranslationShareView({
      syncSttWithShareStatus,
    });
    // 共有状況の監視
    useViewConfigCollection({
      documentId: firestoreToken,
      onDocChanges: onChangeViewConfig,
      onDocError: firestoreConnectionError,
    });

    // Firestoreからログアウト
    useFirestoreLogout();

    return (
      <>
        {/* 共有一時停止完了ダイアログを表示 */}
        <SharePauseCompleteDialog
          isOpen={!isShareActive && isOpenSharePauseCompleteDialog}
          onClickOKButton={onClickOKSharePauseCompleteDialog}
        />
        {/* 共有再開完了ダイアログを表示 */}
        <ShareResumeCompleteDialog
          isOpen={isShareActive && isOpenShareResumeCompleteDialog}
          onClickOKButton={onClickOKShareRestartCompleteDialog}
        />
      </>
    );
  },
);

/**
 * 共有用翻訳画面ビュー
 */
export const ShareTranslationView = React.memo(
  ({ syncSttWithShareStatus }: ShareTranslationViewProps) => {
    const {
      shareViewStatus,
      uid,
      isOpenShareInfoDialogAfterStart,
      setIsOpenShareInfoDialogAfterStart,
      shareURLKey,
      isShared,
      isOpenSharePauseCompleteDialog,
      isOpenShareResumeCompleteDialog,
      isShareActive,
    } = useShareViewInfo();
    const { t } = useTranslation();

    // 翻訳画面表示時に共有状況をチェック
    useShareCheck();

    return (
      <>
        {/* 共有画面情報取得失敗エラーダイアログを表示 */}
        <ShareInfoErrorDialog
          isOpen={shareViewStatus === SHARE_VIEW_STATUS.SHARE_INFO_ERROR}
        />

        {/* 共有開始成功直後に共有ダイアログを表示 */}
        <ShareDialogForHost
          isOpen={isOpenShareInfoDialogAfterStart}
          onClose={() => setIsOpenShareInfoDialogAfterStart(false)}
          shareURLKey={shareURLKey}
        />

        {/* 共有停止中メッセージオーバーレイビューを表示 */}
        <TranslationMessageOverlayView
          isOpen={!isShareActive}
          text={t('shareHost.共有停止中')}
          translationListViewType={TRANSLATION_LIST_VIEW_TYPE.TRANSLATION}
        />

        {/* 共有機能がONになった場合にFirestoreの監視開始 */}
        {uid &&
          (isShared ||
            // 共有中にSHARE_INFO_ERRORになってもFirestoreMonitoringViewは解放しない(解放するとShareViewInfoがリセットされて共有画面情報取得失敗エラーダイアログが表示されないため)
            shareViewStatus === SHARE_VIEW_STATUS.SHARE_INFO_ERROR) && (
            <FirestoreMonitoringView
              firestoreToken={uid}
              isOpenSharePauseCompleteDialog={isOpenSharePauseCompleteDialog}
              isOpenShareResumeCompleteDialog={isOpenShareResumeCompleteDialog}
              isShareActive={isShareActive}
              syncSttWithShareStatus={syncSttWithShareStatus}
            />
          )}
      </>
    );
  },
);
