import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_DEVICE } from '@/constants';
import {
  browserUILanguageSlice,
  BrowserUILanguageState,
} from '@/states/slices/browserUILanguageSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';
import { defaultUiLanguage } from '@/utils/ui';

/**
 * UI言語の設定内容をローカルストレージとReduxに保存 hooks
 *
 * @returns
 */
export const useBrowserUILanguage = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setLanguage, clearUILanguage } = browserUILanguageSlice.actions;

  const { language } = useSelector<RootState, BrowserUILanguageState>(
    (state) => state.browserUILanguage,
  );

  /**
   * UI言語
   */
  const userSettingLanguage = useMemo(() => {
    if (language) {
      return language;
    }

    // ブラウザ言語を取得してデフォルト設定
    return defaultUiLanguage();
  }, [language]);

  /**
   * UI言語を更新
   */
  const changeLanguage = useCallback(
    (value: string) => {
      dispatch(setLanguage(value));
    },
    [dispatch, setLanguage],
  );

  /**
   * UI言語の設定内容に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearUILanguage());
  }, [clearUILanguage, dispatch]);

  return {
    language: userSettingLanguage,
    setLanguage: changeLanguage,
    resetState,
  };
};
