// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ModalDialog,
  QRCode,
  SpinnerOverlayView,
  ToggleButton,
} from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { SHARE_VIEW_TYPE, ShareViewType } from '@/constants/share';
import useOpen from '@/hooks/useOpen';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';

import { useShareDialog } from '../hooks/useShareDialog';

import styles from './ShareDialog.module.scss';
import { ShareEndConfirmDialog } from './ShareEndConfirmDialog';
/**
 * 共有ダイアログ プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
  // 閉じるボタンを表示するか否か
  isHiddenCloseButton: boolean;
  // URL文字列
  url: string;
  // 画面のタイプ（ホストorゲスト）
  shareViewType: ShareViewType;
};

/**
 * 共有ダイアログ
 */
export const ShareDialog = React.memo(
  ({ isOpen, onClose, isHiddenCloseButton, url, shareViewType }: Props) => {
    const {
      clickUrlCopyButton,
      clickCsvDownloadToggleButton,
      viewUpdateState,
    } = useShareDialog();
    const {
      isOpen: isOpenShareEndDialog,
      onOpen: onOpenShareEndDialog,
      onClose: onCloseShareEndDialog,
    } = useOpen();

    const { t } = useTranslation();
    const { isCSVDownloadGuest } = useShareViewInfo();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={isHiddenCloseButton}
        onClickClose={onClose}
        title={t('shareDialog.共有の設定').toString()}
        isShareDialog
      >
        <SpinnerOverlayView
          isOpen={viewUpdateState.status === API_STATUS.LOADING}
        />
        <div className={styles['share-container']}>
          <div className={styles['share-title-wrapper']}>
            {t('shareDialog.共有URL').toString()}
          </div>
          <div className={styles['share-option-contents-wrapper']}>
            <div className={styles['share-description-content']}>
              <div className={styles['share-contents-description-title']}>
                {t('shareDialog.QRコードと共有URL').toString()}
              </div>
              <div className={styles['share-contents-description-details']}>
                {t('shareDialog.QRコードで通訳画面を表示できます。').toString()}
              </div>
            </div>
            <div className={styles['share-qrcode']}>
              <QRCode url={url} />
            </div>
            <div className={styles['url-copy-button-wrapper']}>
              <div className={styles['share-url-wrapper']}>{url}</div>

              <div className={styles['url-copy-button']}>
                <button
                  type="button"
                  onClick={() => {
                    clickUrlCopyButton(url);
                  }}
                  className="dialog-03"
                >
                  {t('shareDialog.コピー')}
                </button>
              </div>
            </div>
          </div>

          {shareViewType === SHARE_VIEW_TYPE.HOST && (
            <>
              <div className={styles['share-title-wrapper']}>
                {t('shareDialog.オプション').toString()}
              </div>
              <div className={styles['share-option-contents-wrapper']}>
                <div className={styles['option-wrapper']}>
                  <div className={styles['option-description']}>
                    <div className={styles['share-contents-description-title']}>
                      {t('shareDialog.通訳履歴ダウンロードの許可')}
                    </div>
                    <div
                      className={styles['share-contents-description-details']}
                    >
                      {t(
                        'shareDialog.共有URLにアクセスしている視聴者が通訳履歴をダウンロードできるようになります。',
                      )}
                    </div>
                  </div>
                  <ToggleButton
                    onChange={() => clickCsvDownloadToggleButton()}
                    checked={isCSVDownloadGuest}
                    gtmClassTag="dialog-13"
                  />
                </div>
              </div>
              <div className={styles['share-title-wrapper']}>
                {t('shareDialog.終了').toString()}
              </div>
              <div className={styles['danger-zone-wrapper']}>
                <div className={styles['option-wrapper']}>
                  <div className={styles['option-description']}>
                    <div className={styles['share-contents-description-title']}>
                      {t('shareDialog.共有の終了')}
                    </div>
                    <div
                      className={styles['share-contents-description-details']}
                    >
                      {t(
                        'shareDialog.共有を終了します。発行済みの共有URLは無効となり、アクセスできなくなります。',
                      )}
                    </div>
                  </div>
                  <div className={styles['suspension-button-wrapper']}>
                    <Button
                      title={t('shareDialog.共有を終了')}
                      type="button"
                      onClick={onOpenShareEndDialog}
                      gtmClassTag="dialog-04"
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {/* 共有終了確認ダイアログ */}
          {isOpenShareEndDialog && (
            <ShareEndConfirmDialog
              isOpen
              onClose={onCloseShareEndDialog}
              onShareEndCompletedFun={onClose} // 共有終了に成功/失敗で共有ダイアログを閉じる
            />
          )}
        </div>
      </ModalDialog>
    );
  },
);
