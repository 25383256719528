import React from 'react';
import { useTranslation } from 'react-i18next';

import feedback from '@/assets/feedback.svg';
import { LinkButtonMenu } from '@/components/Elements';

import { useFeedbackContainer } from '../../hooks/feedback/useFeedbackContainer';

/**
 * フィードバックメニュー プロパティ
 */
type FeedbackContainer = {
  // メニューを閉じる
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > フィードバックメニュー
 */
export const FeedbackContainer = React.memo(
  ({ onCloseMenu }: FeedbackContainer) => {
    const { t } = useTranslation();
    const { feedbackLink } = useFeedbackContainer();

    return (
      <LinkButtonMenu
        title={t('mainMenu.フィードバック')}
        href={feedbackLink}
        isNewTab
        onClick={onCloseMenu}
        imgUrl={feedback}
      />
    );
  },
);
