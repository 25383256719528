import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  shareViewEndInfoSlice,
  ShareViewEndInfoState,
} from '@/states/slices/shareViewEndInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 共有画面情報(終了時用)に関する情報を保存 hooks
 *
 * @returns
 */
export const useShareViewEndInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setIsOpenShareInvalidDialog, resetToInitialState } =
    shareViewEndInfoSlice.actions;

  const { isOpenShareInvalidDialog } = useSelector<
    RootState,
    ShareViewEndInfoState
  >((state) => state.shareViewEndInfo);

  /**
   * 共有終了ダイアログを表示するか否かの判定値を更新
   */
  const changeIsOpenShareInvalidDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareInvalidDialog(value));
    },
    [dispatch, setIsOpenShareInvalidDialog],
  );

  /**
   * 共有画面情報(終了時用)に関する全ての情報をリセット
   */
  const resetShareViewInfoEndState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    isOpenShareInvalidDialog,
    setIsOpenShareInvalidDialog: changeIsOpenShareInvalidDialog,
    resetShareViewInfoEndState,
  };
};
