// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';

import close from '@/assets/close.svg';
import { PortalDocumentBody } from '@/components/Portal';

import styles from './ModalDialog.module.scss';

/**
 * ダイアログのボタンタイプ
 */
export const DIALOG_BUTTON_TYPE = {
  // 情報
  INFO: 'info',
  // 警告
  WARNING: 'warning',
  // キャンセル
  CHANCEL: 'chancel',
};
export type DialogButtonType =
  (typeof DIALOG_BUTTON_TYPE)[keyof typeof DIALOG_BUTTON_TYPE];

/**
 * ダイアログ内に配置するボタン型
 */
export type DialogButton = {
  // ボタン名
  label: string;
  // クリック時の処理
  onClick: () => void;
  // ボタンタイプ(default=info)
  type?: DialogButtonType;
  // ボタンに付与するGTM用のクラスタグ
  gtmClassTag?: string;
};

/**
 * プロパティ
 */
export type DialogMessageContents = {
  children: ReactNode;
};

/**
 * ダイアログ内に配置するメッセージ
 */
export const DialogMessageContents = React.memo(
  ({ children }: DialogMessageContents) => (
    <div className={styles['dialog-msg']}>{children}</div>
  ),
);

/**
 * プロパティ
 */
export type ModalDialog = {
  // タイトル
  title?: string;
  // ダイアログに表示するコンテンツ
  children: ReactNode;
  // ダイアログ内に配置するボタン(複数可)
  buttons?: DialogButton[];
  // クローズボタンクリック時の処理
  onClickClose?: () => void;
  // クローズボタンを非表示にするか(true=非表示|false=表示)
  isHiddenCloseButton?: boolean;
  // ダイアログの表示/非表示(true=表示|false=非表示)
  isOpen?: boolean;
  // 共有ダイアログか否か(共有ダイアログの場合、幅の最大値が別で設定必要なため)
  isShareDialog?: boolean;
};

/**
 * モーダルダイアログ コンポーネント
 *
 * ※
 * エラーダイアログとして表示したい場合は、
 * メッセージはDialogMessageContentsを使う。
 * またキャンセルボタンを表示したい場合はボタンタイプに「CHANCEL」を指定する
 *
 * @param param0
 * @returns
 */
export const ModalDialog = ({
  title = '',
  children,
  buttons = [],
  onClickClose,
  isHiddenCloseButton = true,
  isOpen = false,
  isShareDialog = false,
}: ModalDialog) => {
  if (!isOpen) {
    return null;
  }

  return (
    <PortalDocumentBody>
      <div
        className={styles['dialog-overlay']}
        data-testid="modal-dialog"
        role="button"
        tabIndex={-1}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div
          className={`${
            isShareDialog
              ? styles['dialog-container-for-share-dialog']
              : styles['dialog-container']
          }`}
        >
          <div className={styles['dialog-header']}>
            {/** タイトル */}
            {title.length > 0 && (
              <div
                className={`${!isHiddenCloseButton && styles.left} ${
                  styles.header
                }`}
              >
                <p data-testid="modal-dialog-title">{title}</p>
              </div>
            )}

            {/** クローズボタン */}
            {!isHiddenCloseButton && (
              <div className={styles['dialog-close-container']}>
                <button
                  data-testid="modal-dialog-close-button"
                  className={styles['close-button']}
                  type="button"
                  onClick={onClickClose}
                >
                  <img src={close} alt="close" />
                </button>
              </div>
            )}
          </div>

          {/** ダイアログに表示するコンテンツ */}
          <div className={styles['dialog-content']}>
            <div className={styles['content-primary']}>{children}</div>
          </div>

          {/** ダイアログ内に配置するボタン */}
          {buttons.length > 0 && (
            <div className={styles['dialog-buttons']}>
              {buttons.map((button: DialogButton, i) => {
                const buttonType = button.type
                  ? styles[button.type]
                  : DIALOG_BUTTON_TYPE.INFO;

                return (
                  <button
                    key={button.label} // keyを指定しないと警告がでるのでプロパティに指定されたlabelを設定
                    type="button"
                    onClick={button.onClick}
                    className={`
                      ${styles['dialog-button']}
                      ${buttonType}
                      ${styles['mod-horizontal']}
                      ${i === 0 && buttons.length > 1 && styles['mod-bordered']}
                      ${buttons.length > 1 && styles['multi-buttons']}
                      ${button?.gtmClassTag !== undefined && button.gtmClassTag}
                    `}
                  >
                    <span>{button.label}</span>
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </PortalDocumentBody>
  );
};
