import { BROWSER } from '@/constants';
import { ViewConfigStatus } from '@/constants/firestore';
import { browserAxios } from '@/lib/axios';
import { convertObjectToSneakJson } from '@/utils';

import { API_AUTH_RESULT_CODE } from './apiAuth';
import { ViewConfig } from './createViewApi';

/**
 * リクエストパラメータ
 * ※条件付き必須 いずれか一つ以上のパラメータ指定が必須
 */
export type ViewUpdateApiRequest = {
  // CSVダウンロード許可
  isCsvDownload?: boolean;
  // 共有画面の状態
  status?: ViewConfigStatus;
};

/**
 * 結果コード定数
 */
export const VIEW_UPDATE_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 入力パラメータエラー
    WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
    // URLキーが不正
    WARN_INVALID_URLKEY: 'WARN_INVALID_URLKEY',
    // 不明エラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type ViewUpdateResultCode =
  (typeof VIEW_UPDATE_API_RESULT_CODE)[keyof typeof VIEW_UPDATE_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isViewUpdateApiResultCode = (
  resultCode: string,
): resultCode is ViewUpdateResultCode => {
  if (resultCode in VIEW_UPDATE_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type ViewUpdateApiResponse = {
  // 結果コード
  resultCode: ViewUpdateResultCode;
  // 共有画面情報
  viewConfig: ViewConfig;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (
  request: ViewUpdateApiRequest,
): Promise<ViewUpdateApiResponse> => {
  // パラメータをスネークケースのJSONに変換
  const params = convertObjectToSneakJson(request);

  // リクエスト送信
  const response = browserAxios
    .post<ViewUpdateApiResponse>(BROWSER.API_URL.VIEW_UPDATE, params, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 共有画面更新API リクエスト
 *
 * @returns
 */
export const viewUpdateApi = (
  request: ViewUpdateApiRequest,
): Promise<ViewUpdateApiResponse> => callApi(request);
