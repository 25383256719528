import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // OKボタンクリック時の処理
  onClickOKButton: () => void;
};

/**
 * 共有再開お知らせダイアログ コンポーネント
 */
export const ShareActiveDialog = React.memo(
  ({ isOpen, onClickOKButton }: Props) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton
        title={t('shareActiveDialog.共有が再開されました').toString()}
        buttons={[
          { label: 'OK', onClick: onClickOKButton, gtmClassTag: 'dialog-20' },
        ]}
      >
        <DialogMessageContents>
          {t('shareActiveDialog.主催者により共有が再開されました。')}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
