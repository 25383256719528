import {
  TRANSLATION_LIST_VIEW_TYPE,
  TranslationListViewType,
} from '@/constants';

import styles from './TranslationMessageOverlayView.module.scss';

/**
 * プロパティ
 */
export type Props = {
  // 表示するか否か(true=表示|false=非表示)
  isOpen: boolean;
  // メッセージ
  text: string;
  // 画面タイプ（ホストorゲスト）
  translationListViewType: TranslationListViewType;
};

/**
 * 翻訳画面用
 * メッセージオーバレイビュー コンポーネント
 */
export const TranslationMessageOverlayView = ({
  isOpen = false,
  text,
  translationListViewType,
}: Props) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`${
        translationListViewType === TRANSLATION_LIST_VIEW_TYPE.TRANSLATION
          ? styles['message-layer-outer-host']
          : styles['message-layer-outer-guest']
      }`}
      data-testid="message-overlay-view"
    >
      <div className={styles.message}>
        <span>{text}</span>
      </div>
    </div>
  );
};
