import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  viewUpdateApi,
  ViewUpdateApiResponse,
  isViewUpdateApiResultCode,
  VIEW_UPDATE_API_RESULT_CODE,
  ViewUpdateApiRequest,
} from '../viewUpdateApi';

/**
 * State
 */
export type ViewUpdateState = {
  // レスポンスデータ
  data?: ViewUpdateApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const VIEW_UPDATE_ACTION_TYPE = {
  SET_VIEW_UPDATE: 'SET_VIEW_UPDATE',
  SET_VIEW_UPDATE_SUCCESS: 'SET_VIEW_UPDATE_SUCCESS',
  SET_VIEW_UPDATE_FAILED: 'SET_VIEW_UPDATE_FAILED',
  SET_VIEW_UPDATE_IDLE: 'SET_VIEW_UPDATE_IDLE',
} as const;

/**
 * Action
 */
type ViewUpdateAction = {
  data?: ViewUpdateApiResponse;
  type: keyof typeof VIEW_UPDATE_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<ViewUpdateState, ViewUpdateAction> = (
  state: ViewUpdateState,
  action: ViewUpdateAction,
) => {
  switch (action.type) {
    case VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };

    case VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_IDLE:
      return {
        ...state,
        status: API_STATUS.IDLE,
      };
    default:
      return state;
  }
};

/**
 * 共有画面更新API 呼び出しhooks
 *
 * @returns
 */
export const useViewUpdateApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const updateView = useCallback(
    (request: ViewUpdateApiRequest) => {
      if (state.status === API_STATUS.LOADING) {
        return;
      }

      dispatch({ type: VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE });

      viewUpdateApi(request)
        .then((response: ViewUpdateApiResponse) => {
          // 成功
          if (response.resultCode === VIEW_UPDATE_API_RESULT_CODE.OK) {
            dispatch({
              type: VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_SUCCESS,
              data: response,
            });

            return;
          }

          // 意図しない結果コードの型だった場合は失敗とする
          if (!isViewUpdateApiResultCode(response.resultCode)) {
            dispatch({
              type: VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_FAILED,
              data: {
                ...response,
                resultCode: VIEW_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
              } as ViewUpdateApiResponse,
            });

            return;
          }

          // 失敗
          dispatch({
            type: VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_FAILED,
            data: response,
          });
        })
        .catch((_) => {
          dispatch({
            type: VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_FAILED,
            data: {
              resultCode: VIEW_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
            } as ViewUpdateApiResponse,
          });
        });
    },
    [state.status],
  );

  /**
   * APIのステータスをリセットする
   */
  const resetViewUpdateState = useCallback(() => {
    dispatch({ type: VIEW_UPDATE_ACTION_TYPE.SET_VIEW_UPDATE_IDLE });
  }, []);

  return {
    viewUpdateState: state,
    updateView,
    resetViewUpdateState,
  };
};
