import { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
  API_STATUS,
  DEFAULT_INTERIM_CONTENT,
  RecognizedItem,
  URL_PARAMS_KEY,
} from '@/constants';
import {
  RecognizedTextsData,
  StreamingData,
  VIEW_CONFIG_STATUS,
  ViewConfigData,
  ViewConfigStatus,
} from '@/constants/firestore';
import { GUEST_CAMPAIGN_DISPLAY_TYPE } from '@/constants/ga';
import {
  CREDENTIALS_API_RESULT_CODE,
  GUEST_UPDATE_API_RESULT_CODE,
  GuestUpdateApiResponse,
  useGuestUpdateApi,
} from '@/features/api';
import {
  useFirestoreLoginUseToken,
  useFirestoreLogout,
} from '@/features/firestore';
import { useTextToSpeech } from '@/features/texttospeech';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { useWakeLock } from '@/hooks/useWakeLock';
import { GUEST_STATUS_TYPE } from '@/states/slices/guestDisplaySlice';
import { isGuestCampaignActive } from '@/utils/campaign';
import { convertFirestoreTimestampToDateStr } from '@/utils/date';
import { sendGuestCampaignPopupEvent } from '@/utils/ga';

import { useGuestLanguageInfo } from './useGuestLanguageInfo';

/**
 * 共有ゲスト画面(データ管理用) hooks
 *
 * @returns
 */
export const useShareGuestControl = () => {
  const { updateGuest, guestUpdateState } = useGuestUpdateApi();
  const {
    setDestlang,
    setGuestStatus,
    isCSVDownload,
    setIsCSVDownload,
    shareActiveStatus,
    setShareActiveStatus,
    newViewConfigStatusToActiveChangeStatus,
    checkNewViewConfigStatus,
    setIsOpenShareActiveDialog,
    setISOpenShareInactiveDialog,
  } = useGuestDisplay();
  const { currentDestlang, ttsLangPullDownList } = useGuestLanguageInfo();
  const {
    interimContent,
    recognizedList,
    setInterimContent,
    addNewRecognizedItem,
    resetTranslationResult,
  } = useTranslationInfo();
  const { addTtsList } = useTextToSpeech();
  const { signInFirestore } = useFirestoreLoginUseToken();
  const { addWakeLock, removeWakeLock } = useWakeLock();
  // アンマウント時にFirestoreからログアウト
  useFirestoreLogout();

  /**
   * firestoreを監視する際に使うuid
   *
   * ゲスト画面を表示中は取得したuidを使いまわす
   */
  const firestoreDocumentIdRef = useRef<string>('');

  /**
   * URLから取得した共有画面のURLキー
   */
  const { key } = useParams<{
    [URL_PARAMS_KEY.GUEST.urlKey]: string;
  }>();

  /**
   * 翻訳一覧の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const recognizedListRef = useRef<RecognizedItem[]>(recognizedList);
  useEffect(() => {
    recognizedListRef.current = recognizedList;
  }, [recognizedList]);

  /**
   * 画面表示エラーダイアログを表示
   */
  const showGuestErrorDialog = useCallback(() => {
    setGuestStatus(GUEST_STATUS_TYPE.OTHER);
  }, [setGuestStatus]);

  /**
   * ゲスト画面エラー時の処理
   */
  const guestErrorFunc = useCallback(() => {
    showGuestErrorDialog(); // 画面表示エラーダイアログを表示
    setInterimContent(DEFAULT_INTERIM_CONTENT); // 暫定テキストをリセット
  }, [setInterimContent, showGuestErrorDialog]);

  /**
   * ゲスト情報設定API成功時の処理
   */
  const updateGuestSuccess = useCallback(() => {
    if (firestoreDocumentIdRef.current) {
      // uid取得済の場合はFirestoreログイン済とみなす(何もしない)
      setGuestStatus(GUEST_STATUS_TYPE.SUCCESS);

      return;
    }

    // Firestore接続情報取得APIを呼んでFirestoreにログイン
    signInFirestore(key || '').then((result) => {
      if (result.credentialsApiResultCode === CREDENTIALS_API_RESULT_CODE.OK) {
        // uidを保持
        firestoreDocumentIdRef.current = result.uid;
        // ゲスト画面初回表示に成功
        setShareActiveStatus(VIEW_CONFIG_STATUS.ACTIVE); // デフォルト値
        setIsCSVDownload(false); // デフォルト値
        setGuestStatus(GUEST_STATUS_TYPE.SUCCESS);

        return;
      }

      // Firestore接続情報取得API呼び出し失敗、Firestoreログインに失敗
      guestErrorFunc();
    });
  }, [
    guestErrorFunc,
    key,
    setGuestStatus,
    setIsCSVDownload,
    setShareActiveStatus,
    signInFirestore,
  ]);

  /**
   * ゲスト情報設定API失敗時の処理
   */
  const updateGuestFailed = useCallback(
    (apiResponse: GuestUpdateApiResponse | undefined) => {
      // 超過エラー
      if (
        apiResponse?.resultCode === GUEST_UPDATE_API_RESULT_CODE.INFO_OVER_LIMIT
      ) {
        setGuestStatus(GUEST_STATUS_TYPE.OVER_LIMIT);

        return;
      }

      // URLキー不正エラー
      if (
        apiResponse?.resultCode ===
          GUEST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM ||
        apiResponse?.resultCode ===
          GUEST_UPDATE_API_RESULT_CODE.WARN_INVALID_URLKEY
      ) {
        setGuestStatus(GUEST_STATUS_TYPE.INVALID_URL_KEY);

        return;
      }

      // その他エラー
      guestErrorFunc();
    },
    [guestErrorFunc, setGuestStatus],
  );

  /**
   * Firestoreへの接続に失敗か
   * 接続が切れた
   */
  const firestoreConnectionError = useCallback(() => {
    guestErrorFunc();
  }, [guestErrorFunc]);

  /**
   * 共有終了時の処理
   */
  const shareEndFunc = useCallback(() => {
    // 共有が終了した
    setGuestStatus(GUEST_STATUS_TYPE.SHARE_END);
    // 翻訳結果をリセット
    if (!isCSVDownload) {
      resetTranslationResult();
    } else {
      // 暫定テキストは必ずリセットする
      setInterimContent(DEFAULT_INTERIM_CONTENT);
    }

    // ゲスト向けキャンペーンが有効な場合
    if (isGuestCampaignActive()) {
      // キャンペーンポップアップ表示イベント送信
      sendGuestCampaignPopupEvent({
        display_type: GUEST_CAMPAIGN_DISPLAY_TYPE.SHARE_END,
      });
    }
    setInterimContent(DEFAULT_INTERIM_CONTENT);
  }, [
    isCSVDownload,
    resetTranslationResult,
    setGuestStatus,
    setInterimContent,
  ]);

  /**
   * Firestore>view>configの「status」フィールドの変更を検知した時の処理
   */
  const onChangeStatusField = useCallback(
    (newStatus: ViewConfigStatus) => {
      // Firestoreの共有画面状況を変換
      const newActiveChangeStatus =
        newViewConfigStatusToActiveChangeStatus(newStatus);
      // 変更されたかチェック
      if (newActiveChangeStatus === shareActiveStatus) {
        return;
      }
      // 共有状態を更新
      setShareActiveStatus(newActiveChangeStatus);
      // 共有が再開された
      if (checkNewViewConfigStatus(newStatus)) {
        setIsOpenShareActiveDialog(true);

        return;
      }
      // 共有が一時停止された
      setISOpenShareInactiveDialog(true);
      // 一時停止時にCSVダウンロード不可の場合は翻訳結果をリセット
      if (!isCSVDownload) {
        resetTranslationResult();
      } else {
        // 暫定テキストは必ずリセットする
        setInterimContent(DEFAULT_INTERIM_CONTENT);
      }
    },
    [
      checkNewViewConfigStatus,
      isCSVDownload,
      newViewConfigStatusToActiveChangeStatus,
      resetTranslationResult,
      setISOpenShareInactiveDialog,
      setInterimContent,
      setIsOpenShareActiveDialog,
      setShareActiveStatus,
      shareActiveStatus,
    ],
  );

  /**
   * Firestoreの「共有画面設定(view>config)」が更新された時の処理
   */
  const onChangeViewConfig = useCallback(
    (viewConfigData: ViewConfigData) => {
      // ゲスト画面のダウンロード許可値を設定する
      setIsCSVDownload(viewConfigData.csv_download);
      // 共有の一時停止/再開を検知した場合の処理
      onChangeStatusField(viewConfigData.status);

      if (!viewConfigData.browsable) {
        shareEndFunc();
      }
    },
    [onChangeStatusField, setIsCSVDownload, shareEndFunc],
  );

  /**
   * Firestoreの「ストリーミング翻訳(interim_texts/language)」にデータが追加された時の処理
   * 描画タイミングをrecognizedListと同一にするためReduxに保存する
   */
  const onResultAddStreaming = useCallback(
    (streamingData: StreamingData) => {
      setInterimContent({
        stt: streamingData.stt,
        ttt: streamingData.ttt,
        srclang: streamingData.srclang,
        destlang: streamingData.destlang,
        isReversed: false,
      });
    },
    [setInterimContent],
  );

  /**
   * Firestoreの「確定テキスト(recognized_texts)」にデータが追加された時の処理
   */
  const onResultAddRecognizedTexts = useCallback(
    async (recognizedTextsData: RecognizedTextsData) => {
      // 確定時にリアルタイム結果を消去
      setInterimContent(DEFAULT_INTERIM_CONTENT);

      // 確定テキストをReduxに追加
      addNewRecognizedItem({
        id: recognizedListRef.current.length + 1,
        value: {
          stt: recognizedTextsData.stt,
          ttt: recognizedTextsData.ttt,
          srclang: recognizedTextsData.srclang,
          destlang: recognizedTextsData.destlang,
          date: convertFirestoreTimestampToDateStr(
            recognizedTextsData.timestamp.seconds,
          ),
          isReversed: false,
        },
      });
      // 音声読み上げリストに追加
      addTtsList(recognizedTextsData.ttt, currentDestlang);
    },
    [addNewRecognizedItem, addTtsList, currentDestlang, setInterimContent],
  );

  /**
   * ゲスト情報設定APIの呼び出し状況監視
   */
  useEffect(() => {
    if (
      guestUpdateState.status === API_STATUS.IDLE ||
      guestUpdateState.status === API_STATUS.LOADING
    ) {
      setGuestStatus(GUEST_STATUS_TYPE.LOADING);

      return;
    }

    // 成功
    if (guestUpdateState.status === API_STATUS.SUCCESS) {
      updateGuestSuccess();

      return;
    }

    // 失敗
    if (guestUpdateState.status === API_STATUS.FAILED) {
      updateGuestFailed(guestUpdateState.data);
    }
  }, [guestUpdateState, setGuestStatus, updateGuestFailed, updateGuestSuccess]);

  /**
   * 翻訳先言語が変更された時の処理
   */
  const onChangeDestlang = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setDestlang(value); // destlangが変更されたらcurrentDestlangも変更されるのでここではゲスト情報設定APIは呼ばない
    },
    [setDestlang],
  );

  /**
   * URLキー、currentDestlangが変更された時の処理
   */
  useEffect(() => {
    if (!currentDestlang) {
      return; // 翻訳先言語が取得できていない場合は何もしない
    }
    // ゲスト情報情報設定APIを呼び出す
    updateGuest({ url_key: key || '', destlang: currentDestlang });

    // updateGuestのstateの変更は検知したくないので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, currentDestlang]);

  /**
   * 画面表示(マウント時/アンマウント時)
   */
  useEffect(() => {
    // 画面暗転・ロック制御
    addWakeLock();

    return () => {
      // 画面暗転・ロック制御を解除
      removeWakeLock();
    };

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // 暫定テキストの表示内容
    interimContent,
    // 確定テキストリスト
    recognizedList,
    // 翻訳先言語
    destlang: currentDestlang,
    // 翻訳先言語リスト
    destlangPullDownList: ttsLangPullDownList,
    // 翻訳先言語が変更された時の処理
    onChangeDestlang,
    // Firestoreの「共有画面設定(view>config)」が更新された時の処理
    onChangeViewConfig,
    // Firestoreの「ストリーミング翻訳(interim_texts/language)」にデータが追加された時の処理
    onResultAddStreaming,
    // firestoreの「確定テキスト(recognized_texts)」にデータが追加された時の処理
    onResultAddRecognizedTexts,
    // firestoreへの接続に失敗か接続が切れた時の処理
    firestoreConnectionError,
    // firestore監視に使うuid
    firestoreDocumentId: firestoreDocumentIdRef.current,
    // 共有画面URLキー
    urlKey: key || '',
  };
};
