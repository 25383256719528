import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import {
  useViewUpdateApi,
  VIEW_UPDATE_API_RESULT_CODE,
  ViewUpdateApiRequest,
  ViewUpdateApiResponse,
} from '@/features/api';
import { useNeedAgreement } from '@/features/expired';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { useToastInfo } from '@/hooks/useToastInfo';
import { copyToClipboard } from '@/utils/copy';

/**
 * 共有ダイアログ用 hooks
 *
 * @returns
 */
export const useShareDialog = () => {
  const { addToastMessage } = useToastInfo();
  const { t } = useTranslation();
  const { updateView, viewUpdateState } = useViewUpdateApi();
  const { isCSVDownloadGuest } = useShareViewInfo();
  const { needAgreement } = useNeedAgreement();
  const { setCommonErrorDialogType } = useCommonErrorDialog();

  /**
   * URLコピーボタン押下時の処理
   */
  const clickUrlCopyButton = useCallback(
    (url: string) => {
      copyToClipboard(url).then(() => {
        // 成功のトーストメッセージ表示
        addToastMessage(t('shareDialog.コピーしました'), TOAST_ICON_TYPE.INFO);
      });
    },
    [addToastMessage, t],
  );

  /**
   * CSVダウンロードトグルボタン押下時の処理
   */
  const clickCsvDownloadToggleButton = useCallback(() => {
    // 共有画面情報更新APIリクエスト
    const request: ViewUpdateApiRequest = {
      isCsvDownload: !isCSVDownloadGuest,
    };
    updateView(request);
  }, [isCSVDownloadGuest, updateView]);

  /**
   * 共有画面更新APIの失敗時の処理
   */
  const updateViewFailed = useCallback(
    (apiResponse: ViewUpdateApiResponse | undefined) => {
      // アクセス期限切れエラー
      if (apiResponse?.resultCode === VIEW_UPDATE_API_RESULT_CODE.WARN_AUTH) {
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR);

        return;
      }

      // PTID期限切れエラー
      if (
        apiResponse?.resultCode ===
        VIEW_UPDATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
      ) {
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.PTID_ERROR);

        return;
      }

      // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
      if (
        apiResponse?.resultCode ===
        VIEW_UPDATE_API_RESULT_CODE.INFO_NEED_AGREEMENT
      ) {
        needAgreement();

        return;
      }

      addToastMessage(
        t('shareDialog.通訳履歴ダウンロードの許可の更新に失敗しました'),
        TOAST_ICON_TYPE.WARNING,
      );
    },
    [addToastMessage, needAgreement, setCommonErrorDialogType, t],
  );

  /**
   * 共有画面更新APIの呼び出し状況を監視
   */
  useEffect(() => {
    if (
      viewUpdateState.status === API_STATUS.IDLE ||
      viewUpdateState.status === API_STATUS.LOADING
    ) {
      return;
    }

    // 成功
    if (
      viewUpdateState.status === API_STATUS.SUCCESS &&
      viewUpdateState.data?.resultCode === VIEW_UPDATE_API_RESULT_CODE.OK
    ) {
      // Firestore上のCSVダウンロード可否設定の変更を検知したタイミングでReduxを更新するので、ここでは何もしない
      return;
    }

    updateViewFailed(viewUpdateState.data);
  }, [updateViewFailed, viewUpdateState]);

  return {
    clickUrlCopyButton,
    clickCsvDownloadToggleButton,
    viewUpdateState,
  };
};
