import React from 'react';

import { UILanguageDialog } from '@/features/uilanguage';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';

import { useGuestUILanguage } from '../../hooks/useGuestUILanguage';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * ゲスト画面用 システム言語ダイアログ
 */
export const GuestUILanguageDialog = React.memo(
  ({ isOpen, onClose }: Props) => {
    const { uiLang } = useGuestDisplay();
    const { onChangeUILang } = useGuestUILanguage();

    return (
      <UILanguageDialog
        isOpen={isOpen}
        onClose={onClose}
        onChange={onChangeUILang}
        currentUILang={uiLang}
      />
    );
  },
);
