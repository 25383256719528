import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OptGroupPullDownMenuItem } from '@/components/Elements';
import { AUTO_DETECT_MODE_TYPE, LangData } from '@/constants/language';
import { langList } from '@/features/api';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useBrowserUILanguage } from '@/hooks/useBrowserUILanguage';
import { useLanguageList } from '@/hooks/useLanguageList';
import { convertPullDownMenuItem } from '@/utils/language';

// 翻訳元言語のデフォルト
export const defaultSttLanguage = 'en';
// 翻訳先言語のデフォルト
export const defaultTttLanguage = 'ja';

/**
 * 音声操作種別
 */
const VOICE_TYPE = {
  // 音声認識
  STT: 'stt',
  // 音声合成
  TTS: 'tts',
} as const;
export type VoiceType = (typeof VOICE_TYPE)[keyof typeof VOICE_TYPE];

/**
 * 翻訳言語情報用カスタムフック
 *
 * @returns
 */
export const useLanguageInfo = () => {
  const { srclang, destlang, setSrclang, setDestlang } = useBrowserLanguage();
  const { srcLangList, destLangList, setSrcLangList, setDestLangList } =
    useLanguageList();
  const { language } = useBrowserUILanguage();
  const { isInteractive } = useBrowserTranslationDisplay();
  const { t } = useTranslation();

  /**
   * 取得した言語一覧をグループ付きプルダウンメニュー形式に変換
   */
  const convertOptgroupPullDownMenuItem = useCallback(
    (dataArray: LangData[]): OptGroupPullDownMenuItem[] => {
      if (!dataArray) {
        return [];
      }

      // 双方向モード用
      const interactivePullDownMenuItem: OptGroupPullDownMenuItem = {
        optgroup: { label: t('languageSelectDialog.双方向で通訳可能') },
        items: [],
      };
      // 一方向モード用
      const nonInteractivePullDownMenuItem: OptGroupPullDownMenuItem = {
        optgroup: { label: t('languageSelectDialog.一方向で通訳') },
        items: [],
      };

      dataArray.forEach((element) => {
        Object.entries(element).forEach(([key, value]) => {
          if (value.interactive) {
            interactivePullDownMenuItem.items.push({
              label: value.label,
              value: key,
            });
          } else {
            nonInteractivePullDownMenuItem.items.push({
              label: value.label,
              value: key,
            });
          }
        });
      });

      return [interactivePullDownMenuItem, nonInteractivePullDownMenuItem];
    },
    [t],
  );

  /**
   * STT言語一覧取得
   */
  const fetchSttLangList = useCallback(() => {
    langList({
      voiceType: VOICE_TYPE.STT,
      uiLanguage: language,
    }).then((response) => {
      setSrcLangList(response);
    });
  }, [setSrcLangList, language]);

  /**
   * TTS言語一覧取得
   */
  const fetchTtsLangList = useCallback(() => {
    langList({
      voiceType: VOICE_TYPE.TTS,
      uiLanguage: language,
    }).then((response) => {
      setDestLangList(response);
    });
  }, [setDestLangList, language]);

  /**
   * 翻訳元言語のラベル名
   */
  const srclangLabelName = useMemo((): string => {
    if (!srcLangList || srcLangList.length <= 0) {
      return '';
    }
    const labels = srcLangList.filter((value) => value[srclang]);
    if (!labels || labels.length <= 0) {
      return '';
    }

    return labels[0][srclang].label;
  }, [srcLangList, srclang]);

  /**
   * 翻訳先言語のラベル名
   */
  const destlangLabelName = useMemo((): string => {
    if (!destLangList || destLangList.length <= 0) {
      return '';
    }

    const labels = destLangList.filter((value) => value[destlang]);
    if (!labels || labels.length <= 0) {
      return '';
    }

    return labels[0][destlang].label;
  }, [destLangList, destlang]);

  /**
   * 選択中の翻訳先言語のモードを返す
   *  ・双方向対象言語の場合=双方向モード
   *  ・双方向対象外言語の場合=一方向モード
   */
  const autoDetectMode = useMemo(() => {
    const langData: LangData | undefined = destLangList?.find(
      (value) => value[destlang],
    );

    if (!langData) {
      return AUTO_DETECT_MODE_TYPE.NON_INTERACTIVE;
    }

    return isInteractive && langData[destlang].interactive
      ? AUTO_DETECT_MODE_TYPE.INTERACTIVE
      : AUTO_DETECT_MODE_TYPE.NON_INTERACTIVE;
  }, [destLangList, isInteractive, destlang]);

  /**
   * マウント時の処理
   */
  useEffect(() => {
    // ローカルストレージに翻訳元言語が保存されてない場合は「英語(デフォルト)」を設定
    if (!srclang) {
      setSrclang(defaultSttLanguage);
    }
    // ローカルストレージに翻訳先言語が保存されてない場合は「日本語(デフォルト)」を設定
    if (!destlang) {
      setDestlang(defaultTttLanguage);
    }

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * UI言語が変更された時の処理
   */
  useEffect(() => {
    // UI言語が変更された時、再取得して表示言語を切り替える
    fetchSttLangList();
    fetchTtsLangList();
  }, [fetchSttLangList, fetchTtsLangList]);

  return {
    srclangLabelName,
    destlangLabelName,
    sttLangList: convertPullDownMenuItem(srcLangList),
    ttsLangList: convertOptgroupPullDownMenuItem(destLangList),
    autoDetectMode,
  };
};
