import { useCallback, useEffect, useRef } from 'react';

import { TTSApiResponse, ttsApi } from '@/features/api';
import { useAudioInfo } from '@/hooks/useAudioInfo';
import { useBrowserAudioSetting } from '@/hooks/useBrowserAudioSetting';
import { useGoogleTtsVoiceList } from '@/hooks/useGoogleTtsVoiceList';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { TTSStatus, TTS_STATUS } from '@/states/slices/translationInfoSlice';
import { convertToWavFile } from '@/utils';

import { TTS_AUDIO_CONFIG } from '../constants';

import { useAudio } from './useAudio';
import { useGoogleTtsVoiceListInfo } from './useGoogleTtsVoiceListInfo';

/**
 * 音声読み上げ(TTS) カスタムフック
 *
 * @returns
 */
export const useTextToSpeech = () => {
  const {
    ttsStatus,
    hasTtsRequest,
    addTtsRequest,
    shiftTtsRequestQueue,
    clearTtsRequestQueue,
  } = useTranslationInfo();
  const { isAudioPlay } = useAudioInfo();
  const { play, pause, releaseAudioSource, changePlayDevice } = useAudio();
  const { outputDevice } = useBrowserAudioSetting();
  const { ttsVoiceInfo, isSupportedLangGoogleTTS } = useGoogleTtsVoiceList();

  // マウント時の処理を実行する
  useGoogleTtsVoiceListInfo();

  /**
   * 翻訳一覧を変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const ttsStatusRef = useRef<TTSStatus>(ttsStatus);
  useEffect(() => {
    ttsStatusRef.current = ttsStatus;
  }, [ttsStatus]);

  /**
   * TTSが停止された場合の処理
   */
  useEffect(() => {
    // 音声停止
    if (ttsStatus === TTS_STATUS.PAUSED) {
      // 停止されたらキューの中身を全て破棄(次回は最新のTTSから再生)
      clearTtsRequestQueue();
      // 停止
      pause();
      // 音声破棄
      releaseAudioSource();
    }
  }, [clearTtsRequestQueue, pause, releaseAudioSource, ttsStatus]);

  /**
   * 読み上げリストに追加(TTSするためのリクエスト情報をキューに格納)
   */
  const addTtsList = useCallback(
    (newTtt: string, language: string) => {
      if (ttsStatusRef.current !== TTS_STATUS.READING) {
        // 読み上げ中以外は格納しない
        return;
      }

      if (!isSupportedLangGoogleTTS(language)) {
        // 非対応言語の場合格納しない
        return;
      }

      // TTSリクエスト情報をキューに格納
      addTtsRequest({
        input: { text: newTtt },
        voice: {
          languageCode: ttsVoiceInfo[language].languageCode,
          name: ttsVoiceInfo[language].voiceName,
        },
        audioConfig: {
          audioEncoding: TTS_AUDIO_CONFIG.AUDIO_ENCODING,
          speakingRate: TTS_AUDIO_CONFIG.SPEAKING_RATE,
          pitch: TTS_AUDIO_CONFIG.PITCH,
        },
      });
    },

    [addTtsRequest, isSupportedLangGoogleTTS, ttsVoiceInfo],
  );

  /**
   * TTS APIを呼び出して音声再生
   */
  const playTts = useCallback(async (): Promise<void> => {
    // キューから最新のTTSリクエスト情報を取り出す
    const ttsRequestInfo = shiftTtsRequestQueue();
    if (!ttsRequestInfo) {
      // リクエストデータがない場合は何もしない
      return;
    }

    // TTS API呼び出し
    const requestData = ttsRequestInfo.ttsRequest;
    const response: TTSApiResponse = await ttsApi({
      input: {
        text: requestData.input.text,
      },
      voice: {
        languageCode: requestData.voice.languageCode,
        name: requestData.voice.name,
      },
      audioConfig: {
        audioEncoding: requestData.audioConfig.audioEncoding,
        speakingRate: requestData.audioConfig.speakingRate,
        pitch: requestData.audioConfig.pitch,
      },
    });

    if (!response || !response.audioContent) {
      return;
    }

    const blobUrl = convertToWavFile(response.audioContent);
    if (blobUrl) {
      // 再生
      play(blobUrl);
    }
  }, [play, shiftTtsRequestQueue]);

  /**
   * TTS状況(true=TTS実行中)
   */
  const isTtsPlayRef = useRef<boolean>(false);

  /**
   * TTSリクエスト情報キューを監視
   *
   * ・キューにTTSリクエスト情報が追加されたことを検知してTTS API呼び出しを実施
   * ・APIから結果が返ってきたら順次音声を再生
   */
  useEffect(() => {
    if (ttsStatusRef.current !== TTS_STATUS.READING) {
      // 「読み上げ中」以外は何もしない
      return;
    }

    if (!hasTtsRequest) {
      return; // キューにデータが格納されていない場合は何もしない
    }

    if (isAudioPlay) {
      return; // Audio再生中は何もしない
    }

    if (isTtsPlayRef.current) {
      return; // TTS実行中は何もしない
    }

    // TTS実行
    isTtsPlayRef.current = true;
    playTts().then(() => {
      isTtsPlayRef.current = false;
    });
  }, [hasTtsRequest, isAudioPlay, playTts]);

  /**
   * 出力元デバイスの変更を監視
   */
  useEffect(() => {
    changePlayDevice(outputDevice);
  }, [changePlayDevice, outputDevice]);

  return {
    addTtsList,
  };
};
