import { useCallback } from 'react';

import { useBrowserAudioSetting } from '@/hooks/useBrowserAudioSetting';
import { VAD_THRESHOLD_TYPE } from '@/states/slices/browserAudioSettingSlice';

/**
 * オーディオ設定ダイアログ用 hooks
 *
 * @returns
 */
export const useSettingAudioDialog = () => {
  const { setVadThreshold } = useBrowserAudioSetting();

  /**
   * 弱いボタンクリック
   */
  const clickMediumButton = useCallback(() => {
    setVadThreshold(VAD_THRESHOLD_TYPE.MEDIUM);
  }, [setVadThreshold]);

  /**
   * 強いボタンクリック
   */
  const clickHighButton = useCallback(() => {
    setVadThreshold(VAD_THRESHOLD_TYPE.HIGH);
  }, [setVadThreshold]);

  return {
    // 弱いボタンクリック
    clickMediumButton,
    // 強いボタンクリック
    clickHighButton,
  };
};
