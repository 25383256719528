import React from 'react';
import { useTranslation } from 'react-i18next';

import description from '@/assets/description.svg';
import { LicenseDialog } from '@/features/license';
import useOpen from '@/hooks/useOpen';

/**
 * プロパティ
 */
type LicenseInfoContainer = {
  // メニューを閉じる
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > ライセンス情報メニュー
 */
export const LicenseInfoContainer = React.memo(
  ({ onCloseMenu }: LicenseInfoContainer) => {
    const { isOpen, onClose, onOpen } = useOpen();
    const { t } = useTranslation();

    return (
      <>
        <button
          type="button"
          onClick={() => {
            onOpen();
            onCloseMenu();
          }}
        >
          <img src={description} alt="description" />
          {t('mainMenu.ライセンス情報')}
        </button>

        {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
        {isOpen && <LicenseDialog isOpen onClose={onClose} />}
      </>
    );
  },
);
