import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalDialog, PullDownMenuItem } from '@/components/Elements';
import { UILanguageList } from '@/constants';

import styles from './UILanguageDialog.module.scss';

/**
 * プロパティ
 */
type UILanguageDialog = {
  // システム言語ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // システム言語ダイアログを閉じる
  onClose: () => void;
  // 言語変更時の処理
  onChange: (lang: string) => void;
  // 選択中のUI言語
  currentUILang: string;
};

/**
 * システム言語ダイアログ
 */
export const UILanguageDialog = React.memo(
  ({ isOpen, onClose, onChange, currentUILang }: UILanguageDialog) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={false}
        onClickClose={onClose}
        title={t('mainMenu.システム言語').toString()}
      >
        <div className={styles['ui-lang-list-container']}>
          <ul className={styles['ui-lang-menu-list']}>
            {UILanguageList.map((item: PullDownMenuItem) => (
              <li key={item.value}>
                <button
                  type="button"
                  onClick={() => {
                    onChange(item.value);
                  }}
                >
                  <div
                    className={`${
                      currentUILang === item.value
                        ? styles['language-item-check-icon']
                        : ''
                    }`}
                  >
                    <span>{item.label}</span>
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </ModalDialog>
    );
  },
);
