// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import React from 'react';

import { SHARE_URL_DIRECTORY, SHARE_VIEW_TYPE } from '@/constants/share';

import { ShareDialog } from './ShareDialog';

/**
 * プロパティ
 */
type Props = {
  // 共有ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // 共有ダイアログを閉じる
  onClose: () => void;
  // 共有URL
  shareURLKey: string;
};

/**
 * ホスト画面用共有ダイアログ コンポーネント
 */
export const ShareDialogForHost = React.memo(
  ({ isOpen, onClose, shareURLKey }: Props) => (
    <>
      {/* 共有ダイアログ */}
      <ShareDialog
        isOpen={isOpen}
        onClose={onClose}
        isHiddenCloseButton={false}
        url={`${process.env.REACT_APP_API_DOMAIN}/${SHARE_URL_DIRECTORY}/${shareURLKey}`}
        shareViewType={SHARE_VIEW_TYPE.HOST}
      />
    </>
  ),
);
