/**
 * ポケトークエンジンサーバ
 */
export const HANDSFREE = {
  DOMAIN: {
    // APIドメイン
    API: process.env.REACT_APP_HANDSFREE_API_URL as string,
  },
  API_URL: {
    // TTS API
    TTS: '/tts',
  },
} as const;

/**
 * ブラウザ版同時通訳サーバ
 */
export const BROWSER = {
  DOMAIN: {
    // APIドメイン
    API: process.env.REACT_APP_BROWSER_API_URL as string,
    // WebSocketドメイン
    WS: process.env.REACT_APP_BROWSER_WS_URL as string,
  },

  API_URL: {
    // シリアル認証API
    SERIAL_AUTH: '/pub/serial/auth',
    // ライセンストークン発行API(同通サーバ)
    LICENSE_CREATE: '/private/license/create',
    // ライセンス確認API(同通サーバ)
    LICENSE_INFO: '/private/license/info',
    // トークンチェックAPI
    TOKEN_AUTH: '/pub/token/auth',
    // ログアウトAPI
    LOGOUT: '/private/token/delete',
    // ログアウトAPI(リダイレクト用)
    LOGOUT_REDIRECT: '/private/redirect/token/delete',
    // PTID認証API
    PTID: '/pub/ptid/auth',
    // 利用言語更新API
    LANG_UPDATE: '/private/lang/update',
    // 利用許諾API
    AGREEMENT_UPDATE: '/private/agreement/update',
    // ストリームID発行API
    CREATE: '/private/stream/create',
    // 言語設定API
    LANG: '/private/stream/lang',
    // 音声ストリームAPI(WebSocket)
    STREAM: '/stream',
    // 共有画面作成API
    CREATE_VIEW: '/private/view/create',
    // 共有画面情報取得API
    VIEW_INFO: '/private/view/info',
    // 共有画面破棄API
    DELETE_VIEW: '/private/view/delete',
    // 共有画面更新API
    VIEW_UPDATE: '/private/view/update',
    // ゲスト情報設定API
    GUEST_UPDATE: '/pub/guest/update',
    // Firestore接続情報取得API
    CREDENTIALS: '/pub/firestore/credentials',
  },
  // 言語一覧取得
  LANG_LIST_URL: process.env.REACT_APP_BROWSER_LANG_LIST_URL as string,
  CODEC: 'PCM, 16000, 16, 1, L',
} as const;

/**
 * ポケトーク認証サーバ
 */
export const POCKETALK_AUTH = {
  DOMAIN: process.env.REACT_APP_POCKETALK_AUTH_API_URL as string,
  API_URL: {
    // ログイン認証
    AUTHORIZE: '/authorize',
  },
  // ポケトーク認証用:アプリケーション登録申請後に発行されたclient_id
  CLIENT_ID: process.env.REACT_APP_POCKETALK_AUTH_CLIENT_ID as string,
} as const;

/**
 * PAYサーバ
 */
export const PAY = {
  DOMAIN: process.env.REACT_APP_PAY_URL as string,
  API_URL: {
    // ライセンス確認エンドポイント
    PLM_0100: '/plmweb/plmweb/s/plm0100',
  },
  // 製品コード
  PRODUCT_CODE: '0000305310',
  // モード
  mode: '3',
} as const;

/**
 * API呼び出し状況
 */
export const API_STATUS = {
  // 呼び出し前
  IDLE: 'idle',
  // 呼び出し中
  LOADING: 'loading',
  // 失敗
  FAILED: 'failed',
  // 成功
  SUCCESS: 'success',
} as const;
export type ApiStatus = (typeof API_STATUS)[keyof typeof API_STATUS];

/**
 * Google API
 */
export const GOOGLE = {
  DOMAIN: process.env.REACT_APP_GOOGLE_V1_DOMAIN as string,
  API_URL: {
    TTS: '/text:synthesize?key=',
  },
  KEY: {
    TTS: process.env.REACT_APP_GOOGLE_TTS_KEY as string,
  },
} as const;
