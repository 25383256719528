import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // OKボタン押下時の処理
  onClickOK: () => void;
};

/**
 * 共有超過エラーダイアログ コンポーネント
 */
export const ShareOverLimitDialog = React.memo(
  ({ isOpen, onClickOK }: Props) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t(
          'shareOverLimitDialog.共有人数の上限に達したため表示できません',
        ).toString()}
        buttons={[
          {
            label: t('translationDialog.OK'),
            onClick: onClickOK,
          },
        ]}
      >
        <DialogMessageContents>
          <p />
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
