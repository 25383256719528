import { useCallback, useEffect, useRef } from 'react';

import {
  VIEW_CONFIG_STATUS,
  ViewConfigData,
  ViewConfigStatus,
} from '@/constants/firestore';
import { useShareViewEndInfo } from '@/hooks/useShareViewEndInfo';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';
import { STT_STATUS, STTStatus } from '@/states/slices/translationInfoSlice';

/**
 * 共有の一時停止/再開時に実行したい関数の型
 */
export type SyncSttWithShareStatusFunction = () => void;

/**
 * プロパティ
 */
type Props = {
  // 共有の一時停止/再開でSTT状態を同期させるために実行したい関数
  syncSttWithShareStatus?: SyncSttWithShareStatusFunction;
};

/**
 * 共有中の翻訳画面ビュー用 カスタムフック
 */
export const useTranslationShareView = ({ syncSttWithShareStatus }: Props) => {
  const {
    setShareViewStatus,
    setIsCSVDownloadGuest,
    setSharePauseResumeStatus,
    setIsOpenSharePauseCompleteDialog,
    setIsOpenShareResumeCompleteDialog,
    setIsOpenShareRejoinDialog,
    resetState,
    sharePauseResumeStatus,
  } = useShareViewInfo();
  const { setIsOpenShareInvalidDialog } = useShareViewEndInfo();
  const { sttStatus, resetTranslationResult } = useTranslationInfo();

  /**
   * STT状態をRefで管理
   * ※STTボタン連動時に依存させたくないため
   */
  const sttStatusRef = useRef<STTStatus>(sttStatus);
  useEffect(() => {
    sttStatusRef.current = sttStatus;
  }, [sttStatus]);

  /**
   * 共有の一時停止/再開でSTT状態を同期させるための関数をRefで管理
   */
  const syncSttWithShareStatusRef = useRef<SyncSttWithShareStatusFunction>();
  useEffect(() => {
    syncSttWithShareStatusRef.current = syncSttWithShareStatus;
  }, [syncSttWithShareStatus]);

  /**
   * Firestore>view>configの「共有一時停止」フィールドの変更を検知した時の処理
   *
   * @param newStatus Firestoreに登録されている共有画面状態
   * @param isCSVDownloadGuest Firestoreに登録されているゲストのCSVダウンロード許可値
   */
  const onChangeSharePauseField = useCallback(
    (newStatus: ViewConfigStatus, isCSVDownloadGuest: boolean) => {
      // Firestoreにstatusが登録されていない場合は何もしない
      if (!newStatus) {
        return;
      }

      // 変更されたかチェック
      if (newStatus === sharePauseResumeStatus) {
        return;
      }

      // 共有が一時停止された場合
      if (newStatus === VIEW_CONFIG_STATUS.INACTIVE) {
        // [共有復帰]ダイアログを閉じる
        setIsOpenShareRejoinDialog(false);
        // [共有一時停止完了]ダイアログを表示
        setIsOpenSharePauseCompleteDialog(true);
        // ゲストのCSVダウンロードが許可されていない場合は翻訳結果をリセット
        if (!isCSVDownloadGuest) {
          resetTranslationResult();
        }
        // STT中の場合はSTTを停止
        if (sttStatusRef.current === STT_STATUS.CONNECTING) {
          syncSttWithShareStatusRef.current?.();
        }
      }

      // 共有が再開された場合
      if (newStatus === VIEW_CONFIG_STATUS.ACTIVE) {
        // [共有再開完了]ダイアログを表示
        setIsOpenShareResumeCompleteDialog(true);
        // 共有再開時はリトライするのでこのタイミングではSTTを明示的に再開しない
      }

      // 共有画面状態を保存
      setSharePauseResumeStatus(newStatus);
    },
    [
      resetTranslationResult,
      setIsOpenSharePauseCompleteDialog,
      setIsOpenShareRejoinDialog,
      setIsOpenShareResumeCompleteDialog,
      setSharePauseResumeStatus,
      sharePauseResumeStatus,
    ],
  );

  /**
   * 共有終了時の処理
   */
  const shareEndFunc = useCallback(
    (isCSVDownloadGuest: boolean) => {
      // 終了時にCSVダウンロード不可の場合は翻訳結果をリセット
      if (!isCSVDownloadGuest) {
        resetTranslationResult();
      }
      setShareViewStatus(SHARE_VIEW_STATUS.NONE);
      // 共有終了ダイアログを表示
      setIsOpenShareInvalidDialog(true);
    },
    [resetTranslationResult, setIsOpenShareInvalidDialog, setShareViewStatus],
  );

  /**
   * Firestoreの「画面表示設定(view > config)」の変更を検知した時の処理
   */
  const onChangeViewConfig = useCallback(
    (viewConfigData: ViewConfigData) => {
      // 共有終了時
      if (!viewConfigData.browsable) {
        shareEndFunc(viewConfigData.csv_download);

        return;
      }
      // 共有の一時停止/再開を検知した場合の処理
      onChangeSharePauseField(
        viewConfigData.status,
        viewConfigData.csv_download,
      );
      // 複数端末で同期させるためゲストダウンロード許可値を設定
      setIsCSVDownloadGuest(viewConfigData.csv_download);
    },
    [onChangeSharePauseField, setIsCSVDownloadGuest, shareEndFunc],
  );

  /**
   * Firestoreへの接続に失敗か
   * 接続が切れた
   */
  const firestoreConnectionError = useCallback(() => {
    // 共有情報取得失敗
    setShareViewStatus(SHARE_VIEW_STATUS.SHARE_INFO_ERROR);
  }, [setShareViewStatus]);

  /**
   * 共有一時停止完了ダイアログのOKボタンクリック時の処理
   */
  const onClickOKSharePauseCompleteDialog = () => {
    setIsOpenSharePauseCompleteDialog(false);
  };

  /**
   * 共有再開完了ダイアログのOKボタンクリック時の処理
   */
  const onClickOKShareRestartCompleteDialog = () => {
    setIsOpenShareResumeCompleteDialog(false);
  };

  /**
   * マウント/アンマウント時の処理
   */
  useEffect(
    () => () => {
      // 共有情報リセット
      resetState();
    },

    // コンポーネントのマウント/アンマウント時に一度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    // Firestoreの「画面表示設定(view > config)」の変更を検知した時の処理
    onChangeViewConfig,
    // firestoreへの接続に失敗か接続が切れた時の処理
    firestoreConnectionError,
    // 共有一時停止完了ダイアログのOKボタンクリック時の処理
    onClickOKSharePauseCompleteDialog,
    // 共有再開完了ダイアログのOKボタンクリック時の処理
    onClickOKShareRestartCompleteDialog,
  };
};
