import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * シリアルのモード
 */
export const SERIAL_MODE = {
  // カンファレンス
  CONFERENCE: 'conference',
  // 通常時
  NORMAL: 'normal',
} as const;
export type SerialMode = (typeof SERIAL_MODE)[keyof typeof SERIAL_MODE];

/**
 * State
 */
export type BrowserUserInfoState = {
  // シリアルのモード
  mode: SerialMode;
};

/**
 * 初期State
 */
const initialState: BrowserUserInfoState = {
  mode: SERIAL_MODE.NORMAL,
};

/**
 * ログインユーザ情報 Slice
 */
export const browserUserInfoSlice = createSlice({
  name: 'browserUserInfo',
  initialState,
  reducers: {
    // シリアルのモードを更新
    setMode: (state, action: PayloadAction<SerialMode>) => {
      state.mode = action.payload;
    },
    // リセット
    clearUserInfo: () => initialState,
  },
});
