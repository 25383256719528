import React, { useRef } from 'react';

import {
  PopupDetails,
  PopupSummary,
  PopupContents,
  POPUP_CONTENT_ALIGN_POSITION,
} from '@/components/Elements';
import { DisplayMenuContainer } from '@/features/display';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';
import { useKeyDown } from '@/hooks/useKeyDown';
import useOpen from '@/hooks/useOpen';
import { GUEST_STATUS_TYPE } from '@/states/slices/guestDisplaySlice';

import { GuestShareDialog } from '../GuestShareDialog';

import { GuestCSVDownloadContainer } from './GuestCSVDownloadContainer';
import { GuestShareDialogContainer } from './GuestShareDialogContainer';
import { GuestUILanguageContainer } from './GuestUILanguageContainer';
import styles from './ShareGuestMenuContainer.module.scss';

/**
 * 共有ゲスト画面用メニュー
 */
export const ShareGuestMenuContainer = React.memo(() => {
  const { displayType, setDisplayType, guestStatus, isCSVDownload } =
    useGuestDisplay();

  // ゲスト画面用共有ダイアログのopen/close
  const {
    isOpen: isOpenGuestShareDialog,
    onOpen: onOpenGuestShareDialog,
    onClose: onCloseGuestShareDialog,
  } = useOpen();

  // メニュークリックでopen要素削除する
  const detailsRef = useRef<HTMLDetailsElement | null>(null);

  /**
   * クローズ処理
   */
  const onClose = (): void => {
    detailsRef.current?.removeAttribute('open');
  };
  // Escキーが押下されたらメニューを閉じる
  useKeyDown({ onEscapeKeyDown: onClose });

  return (
    <>
      <div>
        <PopupDetails detailsRef={detailsRef}>
          <PopupSummary>
            <div className={styles['share-guest-menu']}>
              <div className={styles['share-guest-menu-icon']} />
            </div>
          </PopupSummary>
          <PopupContents align={POPUP_CONTENT_ALIGN_POSITION.LEFT_TOP}>
            <div className={styles['share-guest-menu-contents']}>
              <ul className={styles['share-guest-menu-list']}>
                <li>
                  {/* 翻訳元テキストの表示/非表示切り替えメニュー */}
                  <DisplayMenuContainer
                    onCloseMenu={onClose}
                    currentDisplayType={displayType}
                    onChangeDisplayTypeFunc={setDisplayType}
                    gtmClassTag="guest-04"
                  />
                </li>
                <li>
                  {/* UI言語メニュー */}
                  <div
                    className={`${styles['ui-lang']} ${styles['pull-down-icon']}`}
                  >
                    <GuestUILanguageContainer onCloseMenu={onClose} />
                  </div>
                </li>
                {isCSVDownload && (
                  <li>
                    {/* CSVダウンロードメニュー */}
                    <GuestCSVDownloadContainer onCloseMenu={onClose} />
                  </li>
                )}
                <li>
                  {/* 共有URLを表示メニュー */}
                  <GuestShareDialogContainer
                    onCloseMenu={onClose}
                    onClickOpenMenu={onOpenGuestShareDialog}
                    isMenuDisabled={guestStatus !== GUEST_STATUS_TYPE.SUCCESS}
                  />
                </li>
              </ul>
            </div>
          </PopupContents>
        </PopupDetails>
      </div>

      {/* ゲスト画面用共有ダイアログ */}
      <GuestShareDialog
        isOpen={isOpenGuestShareDialog}
        onClose={onCloseGuestShareDialog}
      />
    </>
  );
});
