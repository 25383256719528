import React from 'react';
import { useTranslation } from 'react-i18next';

import settingVoice from '@/assets/settings_voice.svg';
import speaker from '@/assets/volume_up.svg';
import {
  Button,
  ModalDialog,
  PullDownMenu,
  TextBlock,
} from '@/components/Elements';
import { PERMISSION_STATE } from '@/constants';
import { useDeviceMenu } from '@/features/device';
import { useBrowserAudioSetting } from '@/hooks/useBrowserAudioSetting';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { useTranslationSelect } from '@/hooks/useTranslationSelect';
import { VAD_THRESHOLD_TYPE } from '@/states/slices/browserAudioSettingSlice';
import { TTS_STATUS } from '@/states/slices/translationInfoSlice';

import { useSettingAudioDialog } from '../hooks/useSettingAudioDialog';

import styles from './SettingAudioDialog.module.scss';

/**
 * オーディオ設定ダイアログ プロパティ
 */
type SettingAudioDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * オーディオ設定ダイアログ
 */
export const SettingAudioDialog = React.memo(
  ({ isOpen, onClose }: SettingAudioDialog) => {
    const { t } = useTranslation();
    const {
      isChangingOutputAvailable,
      inputDeviceList,
      onChangeInputDevice,
      outputDeviceList,
      onChangeOutputDevice,
      micPermission,
      fetchMicPermission,
    } = useDeviceMenu();
    const selectOpenClose = useTranslationSelect();
    const { inputDevice, outputDevice, vadThreshold } =
      useBrowserAudioSetting();
    const { clickMediumButton, clickHighButton } = useSettingAudioDialog();
    const { ttsStatus } = useTranslationInfo();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={false}
        onClickClose={onClose}
        title={t('mainMenu.オーディオ設定').toString()}
      >
        <div className={styles['setting-audio-dialog-container']}>
          <div className={styles['setting-audio-dialog-col-wrapper']}>
            <div className={styles['setting-audio-dialog-col-section']}>
              <div className={styles['setting-audio-dialog-description']}>
                <img src={settingVoice} alt="settingVoice" />
                <div>{t('settingAudioDialog.マイク')}</div>
              </div>

              {micPermission !== PERMISSION_STATE.ALLOW && (
                <div className={styles['mic-permission']}>
                  {t(
                    'settingAudioDialog.マイクへのアクセスを許可してください',
                  ).toString()}
                  {/* 不許可になっている場合、許可を求めるダイアログを出せない */}
                  {micPermission === PERMISSION_STATE.ASK && (
                    <Button
                      type="button"
                      title={t('settingAudioDialog.許可する').toString()}
                      onClick={fetchMicPermission}
                    />
                  )}
                </div>
              )}
              {micPermission === PERMISSION_STATE.ALLOW && (
                <div className={styles['output-device-list']}>
                  <span className={styles['device-name']}>
                    <PullDownMenu
                      optionItems={inputDeviceList}
                      name="micSelect"
                      selectedValue={inputDevice}
                      onChange={onChangeInputDevice}
                      selectRef={selectOpenClose.selectRef}
                      gtmClassTag="dts-micselect"
                    />
                  </span>
                </div>
              )}
            </div>

            {micPermission === PERMISSION_STATE.ALLOW && (
              <div className={styles['setting-audio-dialog-col-section']}>
                <div className={styles['setting-audio-dialog-description']}>
                  {t('settingAudioDialog.ノイズキャンセリング')}
                </div>
                <ul className={styles['vad-menu-list']}>
                  <li>
                    <button type="button" onClick={clickHighButton}>
                      <div className={`${styles['vad-item']}`}>
                        <div className={`${styles['vad-item-label']}`}>
                          <div
                            className={`${
                              vadThreshold === VAD_THRESHOLD_TYPE.HIGH
                                ? styles['vad-item-checked-icon']
                                : styles['vad-item-unchecked-icon']
                            }`}
                          />
                          <span>{t('settingAudioDialog.強い')}</span>
                        </div>

                        <div className={styles['vad-helper-text']}>
                          <TextBlock
                            text={t(
                              'settingAudioDialog.周囲の騒音が減り、騒がしい環境で効果的です。',
                            ).toString()}
                          />
                        </div>
                      </div>
                    </button>
                  </li>
                  <li>
                    <button type="button" onClick={clickMediumButton}>
                      <div className={`${styles['vad-item']}`}>
                        <div className={`${styles['vad-item-label']}`}>
                          <div
                            className={`${
                              vadThreshold === VAD_THRESHOLD_TYPE.MEDIUM
                                ? styles['vad-item-checked-icon']
                                : styles['vad-item-unchecked-icon']
                            }`}
                          />
                          <span>{t('settingAudioDialog.弱い')}</span>
                        </div>
                        <div className={styles['vad-helper-text']}>
                          <TextBlock
                            text={t(
                              'settingAudioDialog.遠くの音まで拾いやすいですが、ノイズが入りやすくなります。',
                            ).toString()}
                          />
                        </div>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className={styles['setting-audio-dialog-col-wrapper']}>
            <div className={styles['setting-audio-dialog-col-section']}>
              <div className={styles['setting-audio-dialog-description']}>
                <img src={speaker} alt="speaker" />
                <div>{t('settingAudioDialog.スピーカー')}</div>
              </div>
              <div className={styles['output-device-list']}>
                <span
                  className={`${styles['device-name']} ${
                    ((isChangingOutputAvailable &&
                      ttsStatus !== TTS_STATUS.READING) ||
                      !isChangingOutputAvailable) &&
                    styles.disabled
                  }`}
                >
                  <PullDownMenu
                    optionItems={outputDeviceList}
                    name="outputDeviceSelect"
                    selectedValue={outputDevice}
                    onChange={onChangeOutputDevice}
                    disabled={
                      !isChangingOutputAvailable ||
                      ttsStatus !== TTS_STATUS.READING
                    }
                  />
                </span>
                {!isChangingOutputAvailable && (
                  <span>
                    {t('settingAudioDialog.お使いの環境では変更できません')}
                  </span>
                )}
                {isChangingOutputAvailable &&
                  ttsStatus !== TTS_STATUS.READING && (
                    <span>
                      {t('settingAudioDialog.通訳音声の再生中のみ変更できます')}
                    </span>
                  )}
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  },
);
