import React from 'react';

import useOpen from '@/hooks/useOpen';

import { GuestViewErrorDialog } from './error/GuestViewErrorDialog';

/**
 * ゲスト画面エラービュー
 */
export const GuestErrorView = React.memo(() => {
  const { isOpen, onClose } = useOpen(true);

  return (
    <div>
      {/* 画面表示エラーダイアログを表示 */}
      <GuestViewErrorDialog isOpen={isOpen} onClickCancel={onClose} />
    </div>
  );
});
