import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ListDisplayType } from '@/constants';
import { VIEW_CONFIG_STATUS, ViewConfigStatus } from '@/constants/firestore';
import {
  guestDisplaySlice,
  GuestDisplayState,
  GuestStatusType,
  ShareActiveStatus,
} from '@/states/slices/guestDisplaySlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';
import { defaultUiLanguage } from '@/utils/ui';

/**
 * ゲスト画面情報に関する情報を保存 hooks
 *
 * @returns
 */
export const useGuestDisplay = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setDisplayType,
    setFontSize,
    setDestlang,
    setUiLang,
    setGuestStatus,
    setIsCSVDownload,
    setShareActiveChangeStatus,
    setIsOpenShareActiveDialog,
    setISOpenShareInactiveDialog,
    resetToInitialState,
  } = guestDisplaySlice.actions;

  const {
    displayType,
    fontSize,
    destlang,
    uiLang,
    guestStatus,
    isCSVDownload,
    shareActiveStatus,
    isOpenShareActiveDialog,
    isOpenShareInactiveDialog,
  } = useSelector<RootState, GuestDisplayState>((state) => state.guestDisplay);

  /**
   * 翻訳元テキストの表示/非表示を更新
   */
  const changeDisplayType = useCallback(
    (value: ListDisplayType) => {
      dispatch(setDisplayType(value));
    },
    [dispatch, setDisplayType],
  );

  /**
   * 翻訳関連テキストの文字サイズを更新
   */
  const changeFontSize = useCallback(
    (value: number) => {
      dispatch(setFontSize(value));
    },
    [dispatch, setFontSize],
  );

  /**
   * 翻訳先言語を更新
   */
  const changeDestlang = useCallback(
    (value: string) => {
      dispatch(setDestlang(value));
    },
    [dispatch, setDestlang],
  );

  /**
   * UI言語を更新
   */
  const changeUiLanguage = useCallback(
    (value: string) => {
      dispatch(setUiLang(value));
    },
    [dispatch, setUiLang],
  );

  /**
   * ゲスト画面表示状況を更新
   */
  const changeGuestStatus = useCallback(
    (value: GuestStatusType) => {
      dispatch(setGuestStatus(value));
    },
    [dispatch, setGuestStatus],
  );

  /**
   * CSVダウンロードが許可されているか否かの値を更新
   */
  const changeIsCSVDownload = useCallback(
    (value: boolean) => {
      dispatch(setIsCSVDownload(value));
    },
    [dispatch, setIsCSVDownload],
  );

  /**
   * 共有状況の変更状態を更新
   */
  const changeShareActiveStatus = useCallback(
    (value: ShareActiveStatus) => {
      dispatch(setShareActiveChangeStatus(value));
    },
    [dispatch, setShareActiveChangeStatus],
  );

  /**
   * 共有再開ダイアログを表示するか否かの値を更新
   */
  const changeIsShareActiveDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareActiveDialog(value));
    },
    [dispatch, setIsOpenShareActiveDialog],
  );

  /**
   * 共有一時停止ダイアログを表示するか否かの値を更新
   */
  const changeIsShareInactiveDialog = useCallback(
    (value: boolean) => {
      dispatch(setISOpenShareInactiveDialog(value));
    },
    [dispatch, setISOpenShareInactiveDialog],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * UI言語
   */
  const currentUiLanguage = useMemo(() => {
    if (uiLang) {
      return uiLang;
    }

    // ブラウザ言語を取得してデフォルト設定
    return defaultUiLanguage();
  }, [uiLang]);

  /**
   * Firestoreの共有画面状態を、共有状況の変更状態に変換
   */
  const newViewConfigStatusToActiveChangeStatus = useCallback(
    (newConfigViewStatus: ViewConfigStatus) => {
      if (!newConfigViewStatus) {
        return VIEW_CONFIG_STATUS.ACTIVE;
      }

      if (newConfigViewStatus === VIEW_CONFIG_STATUS.ACTIVE) {
        return VIEW_CONFIG_STATUS.ACTIVE;
      }

      return VIEW_CONFIG_STATUS.INACTIVE;
    },
    [],
  );

  /**
   * 新しい共有画面設定の状態を確認
   *
   * @returns true=共有中 / false=共有一時停止中
   */
  const checkNewViewConfigStatus = useCallback(
    (newConfigViewStatus: ViewConfigStatus) => {
      if (newConfigViewStatus === VIEW_CONFIG_STATUS.ACTIVE) {
        return true;
      }

      return false;
    },
    [],
  );

  /**
   * ホストが共有中か否か
   *
   * @returns true=共有中 / false=共有停止中
   */
  const isHostShareActive = useMemo(() => {
    if (shareActiveStatus === VIEW_CONFIG_STATUS.ACTIVE) {
      return true;
    }

    return false;
  }, [shareActiveStatus]);

  return {
    displayType,
    fontSize,
    destlang,
    uiLang: currentUiLanguage,
    guestStatus,
    isCSVDownload,
    shareActiveStatus,
    isOpenShareActiveDialog,
    isOpenShareInactiveDialog,
    setDisplayType: changeDisplayType,
    setFontSize: changeFontSize,
    setDestlang: changeDestlang,
    setUiLang: changeUiLanguage,
    setGuestStatus: changeGuestStatus,
    setIsCSVDownload: changeIsCSVDownload,
    setShareActiveStatus: changeShareActiveStatus,
    setIsOpenShareActiveDialog: changeIsShareActiveDialog,
    setISOpenShareInactiveDialog: changeIsShareInactiveDialog,
    resetState,
    newViewConfigStatusToActiveChangeStatus,
    checkNewViewConfigStatus,
    isHostShareActive,
  };
};
