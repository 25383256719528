import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // OKボタンクリック時の処理
  onClickOKButton: () => void;
};

/**
 * 共有URL一時停止完了ダイアログ コンポーネント
 */
export const SharePauseCompleteDialog = React.memo(
  ({ isOpen, onClickOKButton }: Props) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton
        title={t('sharePauseCompleteDialog.共有を停止しました').toString()}
        buttons={[
          { label: 'OK', onClick: onClickOKButton, gtmClassTag: 'dialog-16' },
        ]}
      >
        <DialogMessageContents>
          {t(
            'sharePauseCompleteDialog.停止中は視聴者に通訳結果が共有されません。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
