/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslationPath } from '../features/header/hooks/useTranslationPath';

import { useTranslationDisplay } from './useTranslationDisplay';

// 遅延実行までの時間
const HIDDEN_TIMEOUT = 3000;

// 画面操作時のイベント
const VIEW_EVENTS = 'click,keyup';

/**
 * フッターの表示/非表示制御 hooks
 *
 * ・3秒間操作がない場合にフッターを非表示にする
 * ・マウス操作時にフッターを表示/非表示する
 * ・メニューを表示中はフッターを非表示にしない
 * ・メニューをとじた後3秒後にフッターを非表示にする
 */
export const useDisplayFooter = () => {
  const { pathname } = useLocation();
  const { isTranslationPath } = useTranslationPath();
  const {
    setFooterDisplay,
    isScrollingTranslation,
    isDisplayFooter,
    isOpenSetting,
  } = useTranslationDisplay();

  // タイマー管理用変数
  const timerIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  // スクロール状態Ref
  const isScrollingTranslationRef = useRef<boolean>(isScrollingTranslation);
  useEffect(() => {
    isScrollingTranslationRef.current = isScrollingTranslation;
  }, [isScrollingTranslation]);
  // フッター表示状態Ref
  const isDisplayFooterRef = useRef<boolean>(isDisplayFooter);
  useEffect(() => {
    isDisplayFooterRef.current = isDisplayFooter;
  }, [isDisplayFooter]);
  // 文字スライダー、入力元デバイスドロップダウンの開閉状態Ref
  const isOpenSettingRef = useRef<boolean>(isOpenSetting);

  /**
   * 3秒後にフッターを非表示にする
   */
  const startHiddenFooterTimer = useCallback(() => {
    timerIdRef.current = setTimeout(() => {
      setFooterDisplay(false);
    }, HIDDEN_TIMEOUT);
  }, [setFooterDisplay]);

  /**
   * 3秒後にフッターを非表示にするためのsetTimeoutを削除
   */
  const stopHiddenFooterTimer = useCallback(() => {
    clearTimeout(timerIdRef.current);
  }, []);

  /**
   * 3秒後にフッターを非表示にするためのsetTimeoutを再登録
   */
  const restartHiddenFooterTimer = useCallback(() => {
    stopHiddenFooterTimer();
    startHiddenFooterTimer();
  }, [startHiddenFooterTimer, stopHiddenFooterTimer]);

  /**
   * メニュー開閉を検知
   */
  useEffect(() => {
    isOpenSettingRef.current = isOpenSetting;

    // メニューが閉じてから3秒後に閉じられるように制御
    if (isOpenSettingRef.current) {
      stopHiddenFooterTimer(); // 開いている時はタイマーを止める
    } else {
      setFooterDisplay(true);
      restartHiddenFooterTimer(); // 閉じている時はタイマーを再開
    }
  }, [
    isOpenSetting,
    restartHiddenFooterTimer,
    setFooterDisplay,
    stopHiddenFooterTimer,
  ]);

  /**
   * マウス操作検知
   */
  useEffect(() => {
    /**
     * イベント登録
     */
    const addEvent = () => {
      VIEW_EVENTS.split(',').forEach((eventName) => {
        window.addEventListener(eventName, mouseEvent);
      });
    };

    /**
     * イベント削除
     */
    const removeEvent = () => {
      stopHiddenFooterTimer();
      VIEW_EVENTS.split(',').forEach((eventName) => {
        window.removeEventListener(eventName, mouseEvent);
      });
    };

    /**
     * マウス操作検知時処理
     */
    const mouseEvent = () => {
      if (!isOpenSettingRef.current && isScrollingTranslationRef.current) {
        // スクロール中は何もしない(ただし、文字スライダーによるスクロールは対象外)
        return;
      }

      // フッター表示されている場合は非表示、非表示の場合は再表示する
      setFooterDisplay(!isDisplayFooterRef.current);

      // setTimeoutを再登録
      restartHiddenFooterTimer();
    };

    // マウント時に実行される
    restartHiddenFooterTimer();
    addEvent();

    // 通訳画面以外は非表示にしない
    if (!isTranslationPath(pathname)) {
      stopHiddenFooterTimer();
    }

    // アンマウント時に実行される
    return () => {
      removeEvent();
      setFooterDisplay(true);
    };

    // マウント/アンマウント時の処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
