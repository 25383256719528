// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DIALOG_BUTTON_TYPE,
  DialogButton,
  DialogMessageContents,
  ModalDialog,
  SpinnerOverlayView,
} from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';

import { useSharePauseConfirmDialog } from '../hooks/useSharePauseConfirmDialog';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * 共有停止確認ダイアログ コンポーネント
 */
export const SharePauseConfirmDialog = React.memo(
  ({ isOpen, onClose }: Props) => {
    const { t } = useTranslation();
    const { clickPauseButton, viewUpdateState } = useSharePauseConfirmDialog({
      onCloseSharePauseConfirmDialog: onClose,
    });
    const { isCSVDownloadGuest } = useShareViewInfo();

    const sharePauseConfirmDialogButtons: DialogButton[] = [
      {
        label: t('sharePauseConfirmDialog.キャンセル'),
        onClick: onClose,
        type: DIALOG_BUTTON_TYPE.CHANCEL,
        gtmClassTag: 'dialog-15',
      },

      {
        label: t('sharePauseConfirmDialog.停止'),
        onClick: clickPauseButton,
        type: DIALOG_BUTTON_TYPE.WARNING,
        gtmClassTag: 'dialog-14',
      },
    ];

    return (
      <>
        <SpinnerOverlayView
          isOpen={viewUpdateState.status === API_STATUS.LOADING}
        />
        <ModalDialog
          isOpen={isOpen}
          title={t('sharePauseConfirmDialog.共有を停止しますか？').toString()}
          buttons={sharePauseConfirmDialogButtons}
        >
          <DialogMessageContents>
            {isCSVDownloadGuest
              ? t('sharePauseConfirmDialog.共有を停止すると')
              : t('sharePauseConfirmDialog.共有を停止するとクリア')}
          </DialogMessageContents>
        </ModalDialog>
      </>
    );
  },
);
