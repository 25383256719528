import React from 'react';

import { SpinnerOverlayView } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { UILanguageDialog } from '@/features/uilanguage';
import { useBrowserUILanguage } from '@/hooks/useBrowserUILanguage';

import { useHostUILanguage } from '../../hooks/uilanguage/useHostUILanguage';

/**
 * プロパティ
 */
type HostUILanguageDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * ホスト画面用 システム言語ダイアログ
 */
export const HostUILanguageDialog = React.memo(
  ({ isOpen, onClose }: HostUILanguageDialog) => {
    const { onChangeUILang, langUpdateStatus } = useHostUILanguage({
      onFailedFun: onClose,
    });
    const { language } = useBrowserUILanguage();

    return (
      <>
        <SpinnerOverlayView isOpen={langUpdateStatus === API_STATUS.LOADING} />
        <UILanguageDialog
          isOpen={isOpen}
          onClose={onClose}
          onChange={onChangeUILang}
          currentUILang={language}
        />
      </>
    );
  },
);
