import { useCallback, useEffect, useRef } from 'react';

import { API_STATUS } from '@/constants';
import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import { LANG_UPDATE_API_RESULT_CODE } from '@/features/api';
import { useLangSynchronize } from '@/features/langupdate';
import { useBrowserUILanguage } from '@/hooks/useBrowserUILanguage';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';
import { sendChangeUiLangEvent } from '@/utils/ga';

/**
 * 処理完了時に実行したい関数の型
 */
export type FailedFunction = () => void;

/**
 * 本カスタムフックのプロパティ
 */
type Props = {
  // 失敗時に実行したい処理
  onFailedFun?: FailedFunction;
};

/**
 * ホスト用 システム言語ダイアログ用 hooks
 *
 * @returns
 */
export const useHostUILanguage = ({ onFailedFun }: Props) => {
  const { setLanguage } = useBrowserUILanguage();
  const { synchronizeLang, langUpdateState: state } = useLangSynchronize();
  const { setCommonErrorDialogType } = useCommonErrorDialog();

  const onFailedFuncRef = useRef<FailedFunction>();
  useEffect(() => {
    onFailedFuncRef.current = onFailedFun;
  }, [onFailedFun]);

  /**
   * 変更されたUI言語をローカルストレージに保存
   */
  const onChangeUILang = useCallback(
    (selectedValue: string) => {
      // 選択されたUI言語をローカルストレージに保存
      setLanguage(selectedValue);
      // 利用言語同期
      synchronizeLang(selectedValue);

      // GAイベントを送信
      sendChangeUiLangEvent({ ui_lang: selectedValue });
    },
    [setLanguage, synchronizeLang],
  );

  // 利用言語更新APIのステータスを監視
  useEffect(() => {
    if (state.status === API_STATUS.FAILED) {
      // 失敗時の処理実行
      onFailedFuncRef.current?.();
      if (state.data?.resultCode === LANG_UPDATE_API_RESULT_CODE.WARN_AUTH) {
        // アクセス期限切れエラー
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR);
      }
    }
  }, [setCommonErrorDialogType, state]);

  return {
    // UI言語が変更された時の処理
    onChangeUILang,
    // 利用言語更新状態
    langUpdateStatus: state.status,
  };
};
