import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_DEVICE } from '@/constants';
import {
  browserAudioSettingSlice,
  BrowserAudioSettingState,
  VAD_THRESHOLD_DEFAULT_VALUE,
  VadThresholdType,
} from '@/states/slices/browserAudioSettingSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * [オーディオ設定ダイアログ]の設定内容をローカルストレージとReduxに保存 hooks
 *
 * @returns
 */
export const useBrowserAudioSetting = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setInputDevice,
    setOutputDevice,
    setAudioThreshold,
    clearAudioSetting,
  } = browserAudioSettingSlice.actions;

  const { inputDevice, outputDevice, vadThreshold } = useSelector<
    RootState,
    BrowserAudioSettingState
  >((state) => state.browserAudioSetting);

  /**
   * 入力元デバイス
   */
  const userSettingInputDevice = useMemo(() => {
    if (inputDevice) {
      return inputDevice;
    }

    return DEFAULT_DEVICE;
  }, [inputDevice]);

  /**
   * 出力元デバイス
   */
  const userSettingOutputDevice = useMemo(() => {
    if (outputDevice) {
      return outputDevice;
    }

    return DEFAULT_DEVICE;
  }, [outputDevice]);

  /**
   * 音声区間検出値
   */
  const userSettingVadThreshold = useMemo(() => {
    if (vadThreshold) {
      return vadThreshold;
    }

    return VAD_THRESHOLD_DEFAULT_VALUE;
  }, [vadThreshold]);

  /**
   * 入力元デバイスを更新
   */
  const changeInputDevice = useCallback(
    (value: string) => {
      dispatch(setInputDevice(value));
    },
    [dispatch, setInputDevice],
  );

  /**
   * 出力元デバイスを更新
   */
  const changeOutputDevice = useCallback(
    (value: string) => {
      dispatch(setOutputDevice(value));
    },
    [dispatch, setOutputDevice],
  );

  /**
   * 音声区間検出値を更新
   */
  const changeVadThreshold = useCallback(
    (value: VadThresholdType) => {
      dispatch(setAudioThreshold(value));
    },
    [dispatch, setAudioThreshold],
  );

  /**
   * [オーディオ設定ダイアログ]の設定内容に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearAudioSetting());
  }, [clearAudioSetting, dispatch]);

  return {
    inputDevice: userSettingInputDevice,
    outputDevice: userSettingOutputDevice,
    vadThreshold: userSettingVadThreshold,
    setInputDevice: changeInputDevice,
    setOutputDevice: changeOutputDevice,
    setVadThreshold: changeVadThreshold,
    resetState,
  };
};
