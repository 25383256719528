// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SpinnerOverlayView } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { SharePauseConfirmDialog } from '@/features/share';
import useOpen from '@/hooks/useOpen';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';

import { useShareResumeButton } from '../../hooks/useShareResumeButton';

import styles from './SharePauseResumeButton.module.scss';

/**
 * 一時停止/再開ボタン コンポーネント
 *
 * @returns
 */
export const SharePauseResumeButton = React.memo(() => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();
  const { isShareActive, isCSVDownloadGuest } = useShareViewInfo();
  const { clickResumeButton, viewUpdateState } = useShareResumeButton();

  // 一時停止/再開ボタン クリック処理
  const handleSharePauseResumeButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (isShareActive) {
      // 停止ボタンクリック時の処理
      onOpen();
      e.stopPropagation();

      return;
    }

    // 再開ボタンクリック時の処理
    clickResumeButton();
  };

  return (
    <>
      <SpinnerOverlayView
        isOpen={viewUpdateState.status === API_STATUS.LOADING}
      />
      <div className={styles['share-pause-resume']}>
        <button
          className={`${styles['share-pause-resume-button']} ${
            isShareActive ? 'header-03' : 'header-02'
          }`}
          onClick={(e) => {
            handleSharePauseResumeButtonClick(e);
          }}
          tabIndex={-1}
          onKeyDown={(e) => e.stopPropagation()}
          data-testid="share-pause-resume-button"
        >
          <span
            className={
              isShareActive
                ? styles['share-pause-icon']
                : styles['share-resume-icon']
            }
          />
          <div className={styles['share-pause-resume-button-container']}>
            {isShareActive &&
              (isCSVDownloadGuest
                ? t('sharePauseResumeButton.共有を停止')
                : t('sharePauseResumeButton.履歴を消して共有を停止'))}
            {!isShareActive && t('sharePauseResumeButton.共有を再開')}
          </div>
        </button>
      </div>

      {/* 共有停止確認ダイアログ */}
      <SharePauseConfirmDialog isOpen={isOpen} onClose={onClose} />
    </>
  );
});
