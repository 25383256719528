import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';
import { ViewConfig } from './createViewApi';

/**
 * 結果コード定数
 */
export const VIEW_INFO_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 不明エラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type ViewInfoResultCode =
  (typeof VIEW_INFO_API_RESULT_CODE)[keyof typeof VIEW_INFO_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isViewInfoApiResultCode = (
  resultCode: string,
): resultCode is ViewInfoResultCode => {
  if (resultCode in VIEW_INFO_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type ViewInfoApiResponse = {
  // 結果コード
  resultCode: ViewInfoResultCode;
  // 共有画面情報
  viewConfig: ViewConfig;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<ViewInfoApiResponse> => {
  // リクエスト送信
  const response = browserAxios
    .get<ViewInfoApiResponse>(BROWSER.API_URL.VIEW_INFO, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 共有画面情報取得API リクエスト
 *
 * @returns
 */
export const viewInfoApi = (): Promise<ViewInfoApiResponse> => callApi();
