import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PullDownMenuItem } from '@/components/Elements';
import { DEFAULT_DEVICE, PERMISSION_STATE } from '@/constants';
import { useBrowserAudioSetting } from '@/hooks/useBrowserAudioSetting';

// 入力元デバイスのkindプロパティ
const AUDIO_INPUT_KIND_KEY = 'audioinput';
// 出力元デバイスのkindプロパティ
const AUDIO_OUTPUT_KIND_KEY = 'audiooutput';

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // 出力元の変更が可能かどうか(true:利用可能)
  isChangingOutputAvailable: boolean;
  // 入力元デバイスリスト
  inputDeviceList: PullDownMenuItem[];
  // 出力元デバイスリスト
  outputDeviceList: PullDownMenuItem[];
  // 入力元デバイスが変更されたときの処理
  onChangeInputDevice: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // 出力元デバイスが変更されたときの処理
  onChangeOutputDevice: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // マイクの権限状態
  micPermission: string;
  // マイクの利用許可を得る
  fetchMicPermission: () => void;
};

/**
 * デバイス一覧用 hooks
 *
 * @returns
 */
export const useDeviceMenu = (): Value => {
  const { inputDevice, setInputDevice, outputDevice, setOutputDevice } =
    useBrowserAudioSetting();

  // マイクの権限
  const [micPermission, setMicPermission] = useState<string>(
    PERMISSION_STATE.ASK,
  );
  // デバイス一覧
  const [deviceList, setDeviceList] = useState<MediaDeviceInfo[] | undefined>(
    undefined,
  );
  const { t } = useTranslation();

  /**
   * デバイス一覧の初期値
   */
  const defaultDeviceArray = useMemo(
    (): PullDownMenuItem[] => [
      {
        label: t('settingAudioDialog.既定のデバイス').toString(),
        value: DEFAULT_DEVICE,
      },
    ],
    [t],
  );

  /**
   * 出力元の変更が可能かどうか
   */
  const isChangingOutputAvailable = useMemo((): boolean => {
    // HTMLMediaElement.setSinkId()が使える環境かどうかで判断する
    const audio = new Audio();
    if (typeof (audio as any)?.setSinkId === 'undefined') {
      return false;
    }

    return true;
  }, []);

  /**
   * 入力元デバイスが変更されたときの処理
   */
  const onChangeInputDevice = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // 選択された入力元デバイスをローカルストレージに保存
      const { value } = e.currentTarget;
      setInputDevice(value);
    },
    [setInputDevice],
  );

  /**
   * 出力元デバイスが変更されたときの処理
   */
  const onChangeOutputDevice = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // 選択された出力元デバイスをローカルストレージに保存
      const { value } = e.currentTarget;
      setOutputDevice(value);
    },
    [setOutputDevice],
  );

  /**
   * 取得したデバイス一覧をプルダウンメニュー形式に変換
   */
  const convertPullDownMenuItem = useCallback(
    (dataArray: MediaDeviceInfo[]): PullDownMenuItem[] => {
      const menuItemArray: PullDownMenuItem[] = [];
      dataArray.forEach((device) => {
        menuItemArray.push({ label: device.label, value: device.deviceId });
      });

      return menuItemArray;
    },
    [],
  );

  /**
   * 入力元デバイス（マイク）一覧
   */
  const inputDeviceList = useMemo((): PullDownMenuItem[] => {
    if (!deviceList) {
      // デバイス一覧が未取得の段階では空にする
      return [];
    }

    const audioInputs = deviceList.filter(
      (device) => device.kind === AUDIO_INPUT_KIND_KEY && device.deviceId,
    );

    if (audioInputs.length > 0) {
      return convertPullDownMenuItem(audioInputs);
    }

    return defaultDeviceArray;
  }, [convertPullDownMenuItem, defaultDeviceArray, deviceList]);

  /**
   * 入力元デバイス一覧の変更を監視して、ローカルストレージに保存しているデバイスが
   * 存在しない場合はinputDeviceをdefaultに設定する
   */
  useEffect(() => {
    if (!inputDeviceList || inputDeviceList.length <= 0) {
      // 入力元デバイス一覧が空のときは何もしない
      return;
    }

    if (
      !inputDeviceList.find(
        (device: PullDownMenuItem) => device.value === inputDevice,
      )
    ) {
      setInputDevice(DEFAULT_DEVICE);
    }
  }, [inputDeviceList, inputDevice, setInputDevice]);

  /**
   * 出力元デバイス（スピーカー）一覧
   */
  const outputDeviceList = useMemo((): PullDownMenuItem[] => {
    if (!deviceList) {
      // デバイス一覧が未取得の段階では空にする
      return [];
    }

    const audioOutputs = deviceList.filter(
      (device) => device.kind === AUDIO_OUTPUT_KIND_KEY && device.deviceId,
    );

    if (audioOutputs.length > 0) {
      return convertPullDownMenuItem(audioOutputs);
    }

    return defaultDeviceArray;
  }, [convertPullDownMenuItem, defaultDeviceArray, deviceList]);

  /**
   * 出力元デバイス一覧の変更を監視して、ローカルストレージに保存しているデバイスが
   * 存在しない場合はoutputDeviceをdefaultに設定する
   */
  useEffect(() => {
    if (!outputDeviceList || outputDeviceList.length <= 0) {
      // 出力元デバイス一覧が空のときは何もしない
      return;
    }

    if (
      !outputDeviceList.find(
        (device: PullDownMenuItem) => device.value === outputDevice,
      )
    ) {
      setOutputDevice(DEFAULT_DEVICE);
    }
  }, [outputDeviceList, outputDevice, setOutputDevice]);

  /**
   * デバイスの一覧を更新する
   */
  const updateDeviceList = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDeviceList(devices);
  }, [setDeviceList]);

  /**
   * マイクの権限を確認する
   */
  const watchPermission = useCallback(async () => {
    const permissionName = 'microphone' as PermissionName;
    await navigator.permissions
      .query({ name: permissionName })
      .then(async (result) => {
        setMicPermission(result.state);

        result.onchange = () => {
          setMicPermission(result.state);
        };
      })
      .catch();
  }, []);

  /**
   * マイクの利用許可を得る
   */
  const fetchMicPermission = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .catch();
  }, []);

  /**
   * マイクの権限状態を監視
   */
  useEffect(() => {
    // デバイスの一覧を更新する
    updateDeviceList();
  }, [updateDeviceList, micPermission]);

  /**
   * デバイスの着脱イベントで一覧を更新する
   */
  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', updateDeviceList);

    return () => {
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        updateDeviceList,
      );
    };
  }, [updateDeviceList]);

  /**
   * マイクの権限を確認する
   */
  useEffect(() => {
    watchPermission();
  }, [watchPermission]);

  return {
    isChangingOutputAvailable,
    inputDeviceList,
    outputDeviceList,
    onChangeInputDevice,
    onChangeOutputDevice,
    micPermission,
    fetchMicPermission,
  };
};
