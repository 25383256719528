import React from 'react';

import useOpen from '@/hooks/useOpen';

import { ShareOverLimitDialog } from './error/ShareOverLimitDialog';

/**
 * 共有人数上限ビュー
 */
export const TotalGuestOverView = React.memo(() => {
  const { isOpen, onClose } = useOpen(true);

  return (
    <div>
      {/* 共有超過エラーダイアログを表示 */}
      <ShareOverLimitDialog isOpen={isOpen} onClickOK={onClose} />
    </div>
  );
});
