import React from 'react';

import { SHARE_VIEW_TYPE } from '@/constants/share';

import { ShareDialog } from '../../share/components/ShareDialog';

/**
 * プロパティ
 */
type Props = {
  // 共有ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // 共有ダイアログを閉じる
  onClose: () => void;
};

/**
 * ゲスト画面用 共有ダイアログ コンポーネント
 */
export const GuestShareDialog = React.memo(({ isOpen, onClose }: Props) => (
  <ShareDialog
    isOpen={isOpen}
    onClose={onClose}
    isHiddenCloseButton={false}
    url={window.location.href} // 表示中の画面URLを表示
    shareViewType={SHARE_VIEW_TYPE.GUEST}
  />
));
