import React from 'react';
import { useTranslation } from 'react-i18next';

import uiLang from '@/assets/language.svg';
import useOpen from '@/hooks/useOpen';

import { GuestUILanguageDialog } from './GuestUILanguageDialog';

/**
 * プロパティ
 */
type Props = {
  // メニューを閉じる処理
  onCloseMenu: () => void;
};

/**
 * ゲスト画面 システム言語メニュー コンポーネント
 */
export const GuestUILanguageContainer = React.memo(({ onCloseMenu }: Props) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useOpen();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
      >
        <img src={uiLang} alt="uiLang" />
        {t('mainMenu.システム言語')}
      </button>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <GuestUILanguageDialog isOpen onClose={onClose} />}
    </>
  );
});
