import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BrowserUILanguageState = {
  // UI言語
  language: string;
};

/**
 * 初期State
 */
const initialState: BrowserUILanguageState = {
  language: '',
};

/**
 * UI言語に関する設定内容を管理 Slice
 */
export const browserUILanguageSlice = createSlice({
  name: 'browserUILanguage',
  initialState,
  reducers: {
    // UI言語を更新
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    // 初期化
    clearUILanguage: () => initialState,
  },
});
