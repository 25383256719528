import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_INTERIM_CONTENT,
  InterimContent,
  RecognizedItem,
} from '@/constants';
import { AutoDetectModeType } from '@/constants/language';
import { FREE_LICENSE_KEY } from '@/features/api';
import { VadThresholdType } from '@/states/slices/browserAudioSettingSlice';
import {
  MicStatus,
  ShareDisplayStatus,
  STTStatus,
  translationInfoSlice,
  TranslationInfoState,
  TTSStatus,
  recognizedListAdapterSelectors,
  SttErrorType,
  ttsRequestQueueAdapterSelectors,
  SHARE_DISPLAY_STATUS,
  STT_STATUS,
  MIC_STATUS,
  TTSRequestInfo,
  RetryStatus,
} from '@/states/slices/translationInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

import { TTSApiRequest } from '../features/api/ttsApi';

/**
 * 翻訳に関する情報を保存 hooks
 *
 * @returns
 */
export const useTranslationInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setLicenseToken,
    setLicenseStr,
    setMicStatus,
    setShareDisplayStatus,
    setSttStatus,
    setTtsStatus,
    setRetryStatus,
    setRecognizedList,
    setInterimContent,
    addNewRecognizedItem,
    setSttErrorType,
    setRemainingTime,
    setTtsRequestQueue,
    addNewTtsRequestQueue,
    resetToInitialState,
    setCurrentAutoDetectMode,
    setCurrentVadThreshold,
  } = translationInfoSlice.actions;

  const {
    licenseToken,
    licenseStr,
    micStatus,
    shareDisplayStatus,
    sttStatus,
    ttsStatus,
    interimContent,
    retryStatus,
    sttErrorType,
    remainingTime,
    currentAutoDetectMode,
    currentVadThreshold,
  } = useSelector<RootState, TranslationInfoState>(
    (state) => state.translationInfo,
  );

  // 翻訳一覧
  const list = useSelector(recognizedListAdapterSelectors.selectAll);
  // TTSリクエスト情報格納キュー
  const ttsRequestQueue = useSelector(
    ttsRequestQueueAdapterSelectors.selectAll,
  );

  /**
   * ライセンストークンを更新
   */
  const changeLicenseToken = useCallback(
    (value: string) => {
      dispatch(setLicenseToken(value));
    },
    [dispatch, setLicenseToken],
  );

  /**
   * ライセンス文字列を更新
   */
  const changeLicenseStr = useCallback(
    (value: string) => {
      dispatch(setLicenseStr(value));
    },
    [dispatch, setLicenseStr],
  );

  /**
   * マイク認識状況を更新
   */
  const changeMicStatus = useCallback(
    (value: MicStatus) => {
      dispatch(setMicStatus(value));
    },
    [dispatch, setMicStatus],
  );

  /**
   * 画面共有状況を更新
   */
  const changeShareDisplayStatus = useCallback(
    (value: ShareDisplayStatus) => {
      dispatch(setShareDisplayStatus(value));
    },
    [dispatch, setShareDisplayStatus],
  );

  /**
   * 音声認識(収録)状況を更新
   */
  const changeSttStatus = useCallback(
    (value: STTStatus) => {
      dispatch(setSttStatus(value));
    },
    [dispatch, setSttStatus],
  );

  /**
   * 音声合成(読み上げ)状況を更新
   */
  const changeTtsStatus = useCallback(
    (value: TTSStatus) => {
      dispatch(setTtsStatus(value));
    },
    [dispatch, setTtsStatus],
  );

  /**
   * リトライ状況を更新
   */
  const changeRetryStatus = useCallback(
    (value: RetryStatus) => {
      dispatch(setRetryStatus(value));
    },
    [dispatch, setRetryStatus],
  );

  /**
   * 暫定テキストを更新
   */
  const changeInterimContent = useCallback(
    (value: InterimContent) => {
      dispatch(setInterimContent(value));
    },
    [dispatch, setInterimContent],
  );

  /**
   * 翻訳画面の表示内容を更新
   */
  const changeRecognizedList = useCallback(
    (value: RecognizedItem[]) => {
      dispatch(setRecognizedList(value));
    },
    [dispatch, setRecognizedList],
  );

  /**
   * 翻訳画面の表示内容を追加
   */
  const addRecognizedItem = useCallback(
    (value: RecognizedItem) => {
      dispatch(addNewRecognizedItem(value));
    },
    [dispatch, addNewRecognizedItem],
  );

  /**
   * 失敗理由更新
   */
  const changeSttErrorType = useCallback(
    (value: SttErrorType) => {
      dispatch(setSttErrorType(value));
    },
    [dispatch, setSttErrorType],
  );

  /**
   * 利用可能残時間（ミリ秒）更新
   */
  const changeRemainingTime = useCallback(
    (value: number) => {
      dispatch(setRemainingTime(value));
    },
    [dispatch, setRemainingTime],
  );

  /**
   * 利用中のプランが「お試しプラン」か否か
   *
   * @returns true=お試しプラン
   */
  const isFreePlan = useMemo((): boolean => {
    if (licenseStr === FREE_LICENSE_KEY) {
      return true;
    }

    return false;
  }, [licenseStr]);

  /**
   * TTSリクエスト情報格納キューを更新
   */
  const changeTtsRequestQueue = useCallback(
    (value: TTSRequestInfo[]) => {
      dispatch(setTtsRequestQueue(value));
    },
    [dispatch, setTtsRequestQueue],
  );

  /**
   * 言語自動判別モード更新
   */
  const changeCurrentAutoDetectMode = useCallback(
    (value: AutoDetectModeType) => {
      dispatch(setCurrentAutoDetectMode(value));
    },
    [dispatch, setCurrentAutoDetectMode],
  );

  /**
   * 音声区間検出値を更新
   */
  const changeCurrentVadThreshold = useCallback(
    (value: VadThresholdType) => {
      dispatch(setCurrentVadThreshold(value));
    },
    [dispatch, setCurrentVadThreshold],
  );

  /**
   * TTSリクエスト情報格納キューにリクエスト情報を追加
   */
  const addTtsRequestQueue = useCallback(
    (value: TTSRequestInfo) => {
      dispatch(addNewTtsRequestQueue(value));
    },
    [dispatch, addNewTtsRequestQueue],
  );

  /**
   * TTSリクエストを追加
   */
  const addTtsRequest = useCallback(
    (newRequest: TTSApiRequest) => {
      addTtsRequestQueue({
        id: uuidv4(),
        ttsRequest: newRequest,
      });
    },
    [addTtsRequestQueue],
  );

  /**
   * 未処理のTTSリクエスト情報が残っているか否か(true=残っている)
   */
  const hasTtsRequest = useMemo(() => {
    if (!ttsRequestQueue) {
      return false;
    }

    return ttsRequestQueue.length > 0;
  }, [ttsRequestQueue]);

  /**
   * キューからTTSリクエスト情報を取り出す
   */
  const shiftTtsRequestQueue = useCallback(() => {
    const ttsRequest = ttsRequestQueue.shift();
    changeTtsRequestQueue(ttsRequestQueue);

    return ttsRequest;
  }, [ttsRequestQueue, changeTtsRequestQueue]);

  /**
   * キューの中身を全て破棄
   */
  const clearTtsRequestQueue = useCallback(() => {
    changeTtsRequestQueue([]);
  }, [changeTtsRequestQueue]);

  /**
   * 翻訳に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * 画面共有中か否か(true=画面共有中)
   */
  const isSharingDisplay = useMemo(() => {
    if (
      shareDisplayStatus === SHARE_DISPLAY_STATUS.SUCCESS ||
      shareDisplayStatus === SHARE_DISPLAY_STATUS.SUCCESS_SCREEN
    ) {
      return true;
    }

    return false;
  }, [shareDisplayStatus]);

  /**
   * 翻訳機能が利用可能か否か(true=利用可能)
   */
  const isUseTranslation = useMemo(() => {
    if (sttStatus === STT_STATUS.READY) {
      return false; // 音声認識準備中
    }
    if (micStatus === MIC_STATUS.ERROR) {
      return false; // マイク認識失敗
    }
    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.ERROR) {
      return false; // 音声キャプチャ共有失敗
    }
    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.NO_AUDIO) {
      return false; // 画面共有開始時に「音声を共有」のチェックがONになっていない
    }

    return true;
  }, [micStatus, shareDisplayStatus, sttStatus]);

  /**
   * 翻訳結果をリセット
   */
  const resetTranslationResult = useCallback(() => {
    // 暫定テキストを初期化
    changeInterimContent(DEFAULT_INTERIM_CONTENT);
    // 確定テキストを初期化
    changeRecognizedList([]);
  }, [changeInterimContent, changeRecognizedList]);

  return {
    licenseToken,
    licenseStr,
    micStatus,
    shareDisplayStatus,
    sttStatus,
    ttsStatus,
    retryStatus,
    interimContent,
    recognizedList: list,
    sttErrorType,
    remainingTime,
    currentAutoDetectMode,
    currentVadThreshold,
    setLicenseToken: changeLicenseToken,
    setLicenseStr: changeLicenseStr,
    setMicStatus: changeMicStatus,
    setShareDisplayStatus: changeShareDisplayStatus,
    setSttStatus: changeSttStatus,
    setTtsStatus: changeTtsStatus,
    setRetryStatus: changeRetryStatus,
    setInterimContent: changeInterimContent,
    setRecognizedList: changeRecognizedList,
    addNewRecognizedItem: addRecognizedItem,
    setSttErrorType: changeSttErrorType,
    setRemainingTime: changeRemainingTime,
    isFreePlan,
    addTtsRequest,
    hasTtsRequest,
    shiftTtsRequestQueue,
    clearTtsRequestQueue,
    setCurrentAutoDetectMode: changeCurrentAutoDetectMode,
    setCurrentVadThreshold: changeCurrentVadThreshold,
    resetState,
    isSharingDisplay,
    isUseTranslation,
    resetTranslationResult,
  };
};
