import { useCallback } from 'react';

import { useGuestDisplay } from '@/hooks/useGuestDisplay';

/**
 * ゲスト画面 UI言語操作 カスタムフック
 *
 * @returns
 */
export const useGuestUILanguage = () => {
  const { setUiLang } = useGuestDisplay();

  /**
   * UI言語が変更された時の処理
   */
  const onChangeUILang = useCallback(
    (value: string) => {
      // 選択されたUI言語をReduxに保存
      setUiLang(value);
    },
    [setUiLang],
  );

  return {
    onChangeUILang,
  };
};
