import { Timestamp } from 'firebase/firestore';

/**
 * 確定テキストのデータ型
 */
export type RecognizedTextsData = {
  // 音声認識テキスト
  stt: string;
  // 翻訳テキスト
  ttt: string;
  // 音声認識言語
  srclang: string;
  // 翻訳先言語
  destlang: string;
  // ドキュメント作成日時
  timestamp: Timestamp;
};

/**
 * 暫定テキストのデータ型
 */
export type InterimTextsData = {
  // 音声認識テキスト
  stt: string;
  // 音声認識言語
  srclang: string;
  // ドキュメント作成日時
  timestamp: Timestamp;
};

/**
 * ストリーミング翻訳のデータ型
 */
export type StreamingData = {
  // 音声認識テキスト
  stt: string;
  // 翻訳テキスト
  ttt: string;
  // 音声認識言語
  srclang: string;
  // 翻訳先言語
  destlang: string;
  // ドキュメント作成日時
  timestamp: Timestamp;
};

/**
 * firestoreに保持しているstatusの状態
 */
export const VIEW_CONFIG_STATUS = {
  // active（共有開始/再開）
  ACTIVE: 'active',
  // inactive（共有一時停止）
  INACTIVE: 'inactive',
};
export type ViewConfigStatus =
  (typeof VIEW_CONFIG_STATUS)[keyof typeof VIEW_CONFIG_STATUS];

/**
 * 共有画面設定のデータ型
 */
export type ViewConfigData = {
  // 共有画面のURLキー
  url_key: string;
  // 共有画面が有効かどうか
  browsable: boolean;
  // CSVダウンロードが許可されているか否か
  csv_download: boolean;
  // 共有が一時停止中か否か
  status: ViewConfigStatus;
  // 共有画面が無効の理由
  close_reason: string;
  // 利用人数上限
  limit_guests: number;
  // 有効期限
  limit_time: Timestamp;
  // ドキュメント作成日時
  timestamp: Timestamp;
};

/**
 * 共有画面利用者数のデータ型
 */
export type ViewSessionData = {
  // 共有画面利用者数
  totalGuest: number;
};
