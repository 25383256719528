import { useCallback, useEffect } from 'react';

import { API_STATUS } from '@/constants';
import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import { VIEW_CONFIG_STATUS } from '@/constants/firestore';
import {
  VIEW_INFO_API_RESULT_CODE,
  ViewInfoApiResponse,
  useViewInfoApi,
} from '@/features/api';
import { useNeedAgreement } from '@/features/expired';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

/**
 * 共有状態チェック hooks
 */
export const useShareCheck = () => {
  const { fetchViewInfo, viewInfoState } = useViewInfoApi();
  const {
    setShareViewStatus,
    updateShareViewInfoOnViewInfoApiResponse,
    setIsOpenShareRejoinDialog,
    resetState,
    isShared,
  } = useShareViewInfo();
  const { needAgreement } = useNeedAgreement();
  const { setCommonErrorDialogType } = useCommonErrorDialog();

  /**
   * 画面情報取得APIの成功時の処理
   */
  const viewInfoSuccess = useCallback(
    (apiResponse: ViewInfoApiResponse) => {
      // 共有画面情報を更新
      updateShareViewInfoOnViewInfoApiResponse(apiResponse).then(() => {
        // 共有復帰ダイアログを表示(共有停止中の場合は表示しない)
        if (
          apiResponse.viewConfig.isBrowsable &&
          apiResponse.viewConfig.status === VIEW_CONFIG_STATUS.ACTIVE
        ) {
          setIsOpenShareRejoinDialog(true);
        }
      });
    },
    [setIsOpenShareRejoinDialog, updateShareViewInfoOnViewInfoApiResponse],
  );

  /**
   * 共有画面情報取得APIの失敗時の処理
   */
  const viewInfoFailed = useCallback(
    (apiResponse: ViewInfoApiResponse | undefined) => {
      // アクセス期限切れエラー
      if (apiResponse?.resultCode === VIEW_INFO_API_RESULT_CODE.WARN_AUTH) {
        setShareViewStatus(SHARE_VIEW_STATUS.WARN_AUTH);
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR);

        return;
      }

      // PTID期限切れエラー
      if (
        apiResponse?.resultCode ===
        VIEW_INFO_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
      ) {
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.PTID_ERROR);

        return;
      }

      // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
      if (
        apiResponse?.resultCode ===
        VIEW_INFO_API_RESULT_CODE.INFO_NEED_AGREEMENT
      ) {
        needAgreement();

        return;
      }

      // 共有情報取得失敗
      setShareViewStatus(SHARE_VIEW_STATUS.SHARE_INFO_ERROR);
    },
    [needAgreement, setCommonErrorDialogType, setShareViewStatus],
  );

  /**
   * 共有画面情報取得APIの呼び出し状況を監視
   */
  useEffect(() => {
    if (
      viewInfoState.status === API_STATUS.IDLE ||
      viewInfoState.status === API_STATUS.LOADING
    ) {
      return;
    }

    // 成功
    if (
      viewInfoState.status === API_STATUS.SUCCESS &&
      viewInfoState.data?.resultCode === VIEW_INFO_API_RESULT_CODE.OK
    ) {
      viewInfoSuccess(viewInfoState.data);

      return;
    }

    // 失敗
    viewInfoFailed(viewInfoState.data);
  }, [viewInfoFailed, viewInfoState, viewInfoSuccess]);

  /**
   * マウント時の処理
   */
  useEffect(() => {
    if (isShared) {
      return undefined; // すでに共有中の場合は何もしない
    }

    // 画面情報取得API呼び出し
    fetchViewInfo();

    return () => {
      // 共有情報をリセット
      resetState();
    };

    // コンポーネントのマウント時に一度だけ実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
