// 順番通りに非同期処理を行いたいのでループ内のawaitを許可するための無効化コメント追加
/* eslint-disable no-await-in-loop */

import { useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { BROWSER } from '@/constants';
import { AUTO_DETECT_MODE_TYPE } from '@/constants/language';
import {
  useStreamApi,
  StreamApiResponse,
  licenseCreateApi,
  createApi,
  langApi,
  licenseInfoApi,
  LICENSE_CREATE_API_RESULT_CODE,
  LICENSE_INFO_API_RESULT_CODE,
  LANG_API_RESULT_CODE,
  CREATE_API_RESULT_CODE,
  CLOSE_EVENT_TYPE,
  CloseEventType,
  viewInfoApi,
  ViewInfoApiResponse,
  VIEW_INFO_API_RESULT_CODE,
} from '@/features/api';
import { useLanguageInfo } from '@/features/selectlanguage';
import { useTtsButton } from '@/features/texttospeech';
import { useBrowserAudioSetting } from '@/hooks/useBrowserAudioSetting';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useInterval } from '@/hooks/useInterval';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';
import {
  RETRY_STATUS,
  STTStatus,
  STT_ERROR_TYPE,
  STT_STATUS,
  SttErrorType,
} from '@/states/slices/translationInfoSlice';
import { checkLicenseTokenExp, calcRemainTimeMillis, sleep } from '@/utils';
import { currentUnixMillisecondsTime } from '@/utils/date';

import { convertCloseEventToSttError } from '../utils/closeEventConverter';

/**
 * リトライ回数上限
 */
const RETRY_MAX_COUNT = 3;
/**
 * リトライ間隔(5秒)
 */
const RETRY_INTERVAL_MILLISECOND = 5000;
/**
 * お試しプラン時の残利用時間更新の定期実行間隔(1分)
 */
const REMAINING_UPDATE_INTERVAL_MILLISECOND = 60000;

/**
 * 本カスタムフックからの返却値
 */
export type UseVoiceInputValue = {
  // 音声認識開始
  requestStartStream: () => void;
  // 音声ストリームAPIにメッセージ送信
  sendAudio: (buffer: any) => void;
  // 音声ストリームAPIのリクエスト停止
  sendFinal: () => void;
  // 音声認識終了
  closeStream: () => void;
  // 音声認識破棄
  discard: () => void;
};

/**
 * プロパティ
 */
export type VoiceInputProps = {
  // Websocket接続中に音声ストリームAPIから返却された結果を受け取る
  onResult: (response: StreamApiResponse) => void;
  // スクリプトプロセッサ開始
  startAudioContext: () => void;
  // スクリプトプロセッサ停止
  stopAudioContext: () => void;
};

/**
 * 音声入力 hooks
 *
 * @param param0
 * @returns
 */
export const useVoiceInput = ({
  onResult,
  startAudioContext,
  stopAudioContext,
}: VoiceInputProps): UseVoiceInputValue => {
  const { openWebSocket, sendAudio, sendFinal, closeWebSocket } =
    useStreamApi();
  const {
    licenseToken,
    sttStatus,
    retryStatus,
    sttErrorType,
    currentAutoDetectMode,
    currentVadThreshold,
    setLicenseToken,
    setLicenseStr,
    setSttStatus,
    setRetryStatus,
    setSttErrorType,
    setRemainingTime,
    resetState,
    isFreePlan,
    setCurrentAutoDetectMode,
    setCurrentVadThreshold,
  } = useTranslationInfo();
  const { pauseTtsOnDestLang } = useTtsButton();
  // ローカルストレージに保存された翻訳先言語/翻訳元言語
  const { srclang, destlang } = useBrowserLanguage();
  // 翻訳言語情報
  const { autoDetectMode } = useLanguageInfo();
  // ローカルストレージに保存された[双方向に通訳]トグル(ON/OFF)
  const { isInteractive } = useBrowserTranslationDisplay();
  // ローカルストレージに保存された ログインユーザ情報
  const { isConferenceMode } = useBrowserUserInfo();
  // ローカルストレージに保存された音声区間検出値
  const { vadThreshold } = useBrowserAudioSetting();
  // ストリームID発行APIから取得したアクセスキー
  const accesskey = useRef<string>('');
  // 共有情報
  const {
    shareViewStatus,
    isShareActive,
    updateShareViewInfoOnViewInfoApiResponse,
  } = useShareViewInfo();

  // リトライ実行中IDを管理するためのRef
  const retryIDRef = useRef(new Set());
  // リトライを中断するIDを管理するためのRef
  const retryAbortIDRef = useRef(new Set());

  // ライセンス確認APIから返却された残時間(※初回)
  const initRemainTimeMillisRef = useRef<number>(0);
  // STT接続が確立された時間(※初回)
  const establishedTimeMillisRef = useRef<number>(0);

  /**
   * ライセンストークンの変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const licenseTokenRef = useRef<string>(licenseToken);
  useEffect(() => {
    licenseTokenRef.current = licenseToken;
  }, [licenseToken]);

  /**
   * 音声認識の失敗理由の変更を監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const sttErrorTypeRef = useRef<string>(sttErrorType);
  useEffect(() => {
    sttErrorTypeRef.current = sttErrorType;
  }, [sttErrorType]);

  /**
   * STT状態を監視してsttStatusRef(useRef)を更新
   *
   * イベントリスナー内のuseStateやReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const sttStatusRef = useRef<STTStatus>(sttStatus);
  useEffect(() => {
    sttStatusRef.current = sttStatus;
  }, [sttStatus]);

  /**
   * 双方向モードの状態をRefで管理
   * ※音声ストリームAPI呼び出し時に依存させたくないため
   */
  const currentAutoDetectModeRef = useRef(currentAutoDetectMode);
  useEffect(() => {
    currentAutoDetectModeRef.current = currentAutoDetectMode;
  }, [currentAutoDetectMode]);

  /**
   * 音声区間検出値の状態をRefで管理
   * ※音声ストリームAPI呼び出し時に依存させたくないため
   */
  const currentVadThresholdRef = useRef(currentVadThreshold);
  useEffect(() => {
    currentVadThresholdRef.current = currentVadThreshold;
  }, [currentVadThreshold]);

  /**
   * 共有画面の状態をRefで管理
   */
  const currentShareViewStatusRef = useRef(shareViewStatus);
  useEffect(() => {
    currentShareViewStatusRef.current = shareViewStatus;
  }, [shareViewStatus]);

  /**
   * ライセンストークンとアクセスキーの両方が揃っているか確認
   *
   * @returns true=両方揃っている
   */
  const hasAuthInfo = useCallback((): boolean => {
    if (!licenseTokenRef.current) {
      return false;
    }
    if (!accesskey.current) {
      return false;
    }

    return true;
  }, []);

  /**
   * ライセンストークンとアクセスキーを削除
   */
  const resetAuthInfo = useCallback(() => {
    setLicenseToken('');
    accesskey.current = '';
  }, [setLicenseToken]);

  /**
   * 共有中か否か
   *
   * @returns true=共有中
   */
  const isSharedStatus = () => {
    if (currentShareViewStatusRef.current === SHARE_VIEW_STATUS.SHARED) {
      return true;
    }

    return false;
  };

  /**
   * ライセンス確認API呼び出し
   * ・ライセンス確認API(エンジンサーバ)を呼び出してライセンス期限チェック
   * ・ライセンス確認APIから「月残時間」が返却されたかチェック
   */
  const callLicenseInfo = useCallback(async (): Promise<SttErrorType> => {
    // ライセンス確認API呼び出し
    const licenseInfoApiResponse = await licenseInfoApi();
    switch (licenseInfoApiResponse.resultCode) {
      case LICENSE_INFO_API_RESULT_CODE.OK:
        break; // 後述の処理継続
      case LICENSE_INFO_API_RESULT_CODE.INFO_EXPIRED_LICENSE:
        return STT_ERROR_TYPE.LICENSE_EXP;
      case LICENSE_INFO_API_RESULT_CODE.WARN_AUTH:
        return STT_ERROR_TYPE.ACCESS_EXP; // アクセス期限切れ
      case LICENSE_INFO_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
        return STT_ERROR_TYPE.PTID_EXP; // PTID期限切れ
      case LICENSE_INFO_API_RESULT_CODE.INFO_NEED_AGREEMENT:
        return STT_ERROR_TYPE.NEED_AGREEMENT; // 利用規約が更新されている
      default:
        return STT_ERROR_TYPE.OTHER;
    }
    setLicenseStr(licenseInfoApiResponse.licenseStr);

    // ライセンス確認APIから返却された「月残時間」が正数の場合のみ保存（正数=お試しプラン利用/負数=お試しプラン以外）
    const timeMillis = licenseInfoApiResponse.remainingTimeMillis;
    if (timeMillis >= 0) {
      setRemainingTime(timeMillis);
      initRemainTimeMillisRef.current = timeMillis;
    }

    return STT_ERROR_TYPE.NONE;
  }, [setLicenseStr, setRemainingTime]);

  /**
   * お試しプランの利用残時間の更新
   */
  const updateRemainingTime = useCallback(async () => {
    setRemainingTime(
      calcRemainTimeMillis(
        initRemainTimeMillisRef.current,
        establishedTimeMillisRef.current,
      ),
    );
  }, [setRemainingTime]);

  /**
   * 定期実行
   */
  const { startTimer, stopTimer } = useInterval({
    onUpdate: updateRemainingTime,
    interval: REMAINING_UPDATE_INTERVAL_MILLISECOND,
    autoStart: false,
  });

  /**
   * STTが開始されたら「お試しプランの場合のみ残利用時間更新」を開始
   */
  useEffect(() => {
    if (sttStatus === STT_STATUS.CONNECTING && isFreePlan) {
      establishedTimeMillisRef.current = currentUnixMillisecondsTime();
      startTimer();

      return;
    }

    stopTimer();
  }, [isFreePlan, startTimer, stopTimer, sttStatus]);

  /**
   * 以下のAPIを呼んで音声認識の準備を行う
   * ・ライセンストークン発行API(同通サーバ)
   * ・ストリームID発行API(エンジンサーバ)
   *
   * @returns エラー理由
   */
  const readyStream = useCallback(async (): Promise<SttErrorType> => {
    try {
      // ライセンストークン発行API呼び出し
      const licenseCreateApiResponse = await licenseCreateApi();
      switch (licenseCreateApiResponse.resultCode) {
        case LICENSE_CREATE_API_RESULT_CODE.OK:
          break; // 後述の処理継続
        case LICENSE_CREATE_API_RESULT_CODE.INFO_LIMIT_EXCEEDED:
          return STT_ERROR_TYPE.FREE_EXP;
        case LICENSE_CREATE_API_RESULT_CODE.WARN_INVALID_AUTH:
          return STT_ERROR_TYPE.LICENSE_EXP;
        case LICENSE_CREATE_API_RESULT_CODE.WARN_AUTH:
          return STT_ERROR_TYPE.ACCESS_EXP; // アクセス期限切れ
        case LICENSE_CREATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
          return STT_ERROR_TYPE.PTID_EXP; // PTID期限切れ
        case LICENSE_CREATE_API_RESULT_CODE.INFO_NEED_AGREEMENT:
          return STT_ERROR_TYPE.NEED_AGREEMENT; // 利用規約が更新されている
        default:
          return STT_ERROR_TYPE.OTHER;
      }

      // ライセンストークン発行APIから返却されたライセンストークンをReduxに保存
      const responseLicenseToken = licenseCreateApiResponse.token;
      setLicenseToken(responseLicenseToken);

      // ストリームID発行API呼び出し
      const streamCreateApiResponse = await createApi({
        licenseToken: responseLicenseToken,
        codec: BROWSER.CODEC,
        srclang,
        destlang,
      });
      switch (streamCreateApiResponse.resultCode) {
        case CREATE_API_RESULT_CODE.OK:
          break;
        case CREATE_API_RESULT_CODE.WARN_AUTH:
          return STT_ERROR_TYPE.ACCESS_EXP; // アクセス期限切れ
        case CREATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
          return STT_ERROR_TYPE.PTID_EXP; // PTID期限切れ
        case CREATE_API_RESULT_CODE.INFO_NEED_AGREEMENT:
          return STT_ERROR_TYPE.NEED_AGREEMENT; // 利用規約が更新されている
        default:
          return STT_ERROR_TYPE.OTHER;
      }
      accesskey.current = streamCreateApiResponse.accessKey;

      return STT_ERROR_TYPE.NONE;
    } catch {
      return STT_ERROR_TYPE.OTHER;
    }
  }, [destlang, setLicenseToken, srclang]);

  /**
   * Websocketがクローズされた場合の処理
   */
  const websocketClose = useCallback(
    (closeEventType: CloseEventType) => {
      if (sttStatusRef.current !== STT_STATUS.CONNECTING) {
        return;
      }

      // 正常に切断された場合は何もしない
      if (closeEventType === CLOSE_EVENT_TYPE.SUCCESS) {
        return;
      }

      // 音声ストリームAPIからINFO_LIMIT_EXCEEDEDで切断された場合はリトライせずに無料期間終了ダイアログを表示
      if (closeEventType === CLOSE_EVENT_TYPE.LIMIT_EXCEEDED_ERROR) {
        setSttStatus(STT_STATUS.ERROR);
        setSttErrorType(STT_ERROR_TYPE.FREE_EXP);

        return;
      }

      // 音声ストリームAPIからINFO_CONNECTION_SHIFTEDで切断された場合はリトライせずにリトライ失敗エラーダイアログを表示
      if (closeEventType === CLOSE_EVENT_TYPE.CONNECTION_SHIFTED_ERROR) {
        setSttStatus(STT_STATUS.ERROR);
        setSttErrorType(STT_ERROR_TYPE.OTHER);

        return;
      }

      // リトライ実行
      setRetryStatus(RETRY_STATUS.RETRY);
    },
    [setRetryStatus, setSttErrorType, setSttStatus],
  );

  /**
   * 以下の処理を行ってWebsocketを介して音声ストリームに接続する
   * ・Websocketを介して音声ストリームに接続
   * ・スクリプトプロセッサ開始
   *
   * @returns エラー理由
   */
  const connectStream = useCallback(async (): Promise<SttErrorType> => {
    try {
      // Websocketを介して音声ストリームに接続
      // 音声ストリームAPIリクエスト開始
      const result = await openWebSocket(
        {
          accessKey: accesskey.current,
          pushMode: false,
          autoDetect: autoDetectMode === AUTO_DETECT_MODE_TYPE.INTERACTIVE,
          licenseToken: licenseTokenRef.current,
          noiseSuppression: vadThreshold,
          interimResults: true, // 必ずストリーミング翻訳する
        },
        onResult,
        websocketClose,
      );
      if (!result.closeEventType) {
        // Websocket接続成功
        setSttStatus(STT_STATUS.CONNECTING);
        // スクリプトプロセッサ開始
        startAudioContext();

        // 「言語自動判別モード」を判定してReduxに保存
        setCurrentAutoDetectMode(autoDetectMode);
        // 「音声区間検出値」をReduxに保存
        setCurrentVadThreshold(vadThreshold);

        return STT_ERROR_TYPE.NONE;
      }

      // Websocket接続失敗
      // この時点ではNONE/OTHERしか返らない(FREE_EXPとCONNECTION_SHIFTEDはWebsocket切断時のみ発生)
      return convertCloseEventToSttError(result.closeEventType);
    } catch {
      return STT_ERROR_TYPE.OTHER;
    }
  }, [
    autoDetectMode,
    onResult,
    openWebSocket,
    setCurrentAutoDetectMode,
    setCurrentVadThreshold,
    setSttStatus,
    startAudioContext,
    vadThreshold,
    websocketClose,
  ]);

  /**
   * 開始処理
   *
   * @returns エラー理由
   */
  const startStream = useCallback(async (): Promise<SttErrorType> => {
    // 音声認識状態を「準備中」に変更
    setSttStatus(STT_STATUS.READY);

    try {
      // カンファレンス用シリアルでログイン中の場合のみ、
      // 共有状況を同期させるために、共有中以外の場合は共有画面の有効チェック(共有中の場合はFirestore監視で無効を検知できるため)
      if (isConferenceMode && !isSharedStatus()) {
        const viewInfoResponse: ViewInfoApiResponse = await viewInfoApi();
        switch (viewInfoResponse.resultCode) {
          case VIEW_INFO_API_RESULT_CODE.OK:
            break;
          case VIEW_INFO_API_RESULT_CODE.WARN_AUTH:
            return STT_ERROR_TYPE.ACCESS_EXP; // アクセス期限切れ
          case VIEW_INFO_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
            return STT_ERROR_TYPE.PTID_EXP; // PTID期限切れ
          case VIEW_INFO_API_RESULT_CODE.INFO_NEED_AGREEMENT:
            return STT_ERROR_TYPE.NEED_AGREEMENT; // 利用規約が更新されている
          default:
            return STT_ERROR_TYPE.OTHER;
        }
        // 共有画面情報取得APIからの返却結果をReduxに保存
        updateShareViewInfoOnViewInfoApiResponse(viewInfoResponse);
      }

      // ライセンストークン・アクセスキー未取得 or ライセンストークンが期限切れ
      if (!hasAuthInfo() || !checkLicenseTokenExp(licenseTokenRef.current)) {
        // ライセンストークンとアクセスキーを削除
        resetAuthInfo();
        // ライセンストークン発行API、ストリームID発行API
        const readyResult = await readyStream();
        if (readyResult !== STT_ERROR_TYPE.NONE) {
          return readyResult;
        }
      }

      // 言語設定API呼び出し
      const langApiResponse = await langApi({
        srclang,
        destlang,
      });
      switch (langApiResponse.resultCode) {
        case LANG_API_RESULT_CODE.OK:
          break;
        case LANG_API_RESULT_CODE.WARN_AUTH:
          return STT_ERROR_TYPE.ACCESS_EXP; // アクセス期限切れ
        case LANG_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
          return STT_ERROR_TYPE.PTID_EXP; // PTID期限切れ
        case LANG_API_RESULT_CODE.INFO_NEED_AGREEMENT:
          return STT_ERROR_TYPE.NEED_AGREEMENT; // 利用規約が更新されている
        default:
          return STT_ERROR_TYPE.OTHER;
      }

      // ライセンス確認API呼び出し
      const licenseInfoResult = await callLicenseInfo();
      if (licenseInfoResult !== STT_ERROR_TYPE.NONE) {
        return licenseInfoResult;
      }
    } catch {
      return STT_ERROR_TYPE.OTHER;
    }

    // Websocketを介して音声ストリームに接続
    const connectResult = await connectStream();

    return connectResult;
  }, [
    callLicenseInfo,
    connectStream,
    destlang,
    hasAuthInfo,
    isConferenceMode,
    readyStream,
    resetAuthInfo,
    setSttStatus,
    srclang,
    updateShareViewInfoOnViewInfoApiResponse,
  ]);

  /**
   * 音声認識リトライ
   */
  const retryStream = useCallback(async () => {
    let retryCount = 0;
    let retryResult = STT_ERROR_TYPE.NONE;

    if (retryStatus === RETRY_STATUS.RETRYING) {
      // すでにリクエスト中は何もしない
      return;
    }

    // リトライ開始
    setRetryStatus(RETRY_STATUS.RETRYING);
    // リトライID発行して格納
    const myRetryID = uuidv4();
    retryIDRef.current.add(myRetryID);

    // スクリプトプロセッサ停止
    stopAudioContext();
    // Websocket閉じる
    closeWebSocket();
    while (retryCount < RETRY_MAX_COUNT) {
      // 中断されたリトライの場合は終了(リトライ実行中に本カスタムがアンマウントされた時を想定)
      if (retryAbortIDRef.current && retryAbortIDRef.current.has(myRetryID)) {
        setRetryStatus(RETRY_STATUS.NONE);

        break;
      }

      if (sttErrorTypeRef.current !== STT_ERROR_TYPE.NONE) {
        // Reduxで管理している「音声認識の失敗理由」が「なし」か確認
        // すでにエラーダイアログ表示中の場合はリトライ終了
        setRetryStatus(RETRY_STATUS.NONE);

        break;
      }

      // 待つ
      if (retryCount > 0) {
        await sleep(RETRY_INTERVAL_MILLISECOND);
      }
      // 再試行
      retryResult = await startStream();
      if (retryResult === STT_ERROR_TYPE.NONE) {
        // リトライ状態をリセット
        setRetryStatus(RETRY_STATUS.NONE);

        break;
      }
      retryCount += 1;
    }

    // 実行完了したリトライIDを削除(中断されたリトライはloop終了後に解放されるので明示的に削除しない)
    retryIDRef.current.delete(myRetryID);

    if (retryCount >= RETRY_MAX_COUNT) {
      // リトライ終了
      setRetryStatus(RETRY_STATUS.NONE);
      // エラー
      setSttStatus(STT_STATUS.ERROR);
      // 失敗理由
      setSttErrorType(retryResult);
    }
  }, [
    closeWebSocket,
    retryStatus,
    setRetryStatus,
    setSttErrorType,
    setSttStatus,
    startStream,
    stopAudioContext,
  ]);

  /**
   * 音声認識開始
   *
   * @returns
   */
  const requestStartStream = useCallback(async () => {
    // 音声認識状態が「停止中」か確認
    if (
      sttStatusRef.current !== STT_STATUS.PAUSED &&
      sttStatusRef.current !== STT_STATUS.INACTIVE
    ) {
      return;
    }

    const result = await startStream();
    if (result !== STT_ERROR_TYPE.NONE) {
      // リトライ
      setRetryStatus(RETRY_STATUS.RETRY);
    }
  }, [setRetryStatus, startStream]);

  /**
   * 音声認識停止
   *
   * @returns
   */
  const closeStream = useCallback(async () => {
    // Reduxで管理している「音声認識状態」が「Websocketを介して音声ストリームに接続中」か
    if (sttStatus !== STT_STATUS.CONNECTING) {
      // 何もしない
      return;
    }

    // スクリプトプロセッサ停止
    stopAudioContext();
    // Websocket閉じる
    closeWebSocket();
    // Reduxで管理している「音声認識状態」を「停止中」にする
    setSttStatus(STT_STATUS.PAUSED);
  }, [closeWebSocket, setSttStatus, stopAudioContext, sttStatus]);

  /**
   * リトライ開始リクエスト
   */
  const requestRetryStart = useCallback(() => {
    // ライセンストークンとアクセスキーを削除
    resetAuthInfo();
    // リトライ
    setRetryStatus(RETRY_STATUS.RETRY);
  }, [resetAuthInfo, setRetryStatus]);

  /**
   * リトライチェック処理の実行が可能か否か
   *
   * @returns true=リトライ必要/false=不要
   */
  const shouldRetryCheck = useCallback((): boolean => {
    // Websocket接続中以外は何もしない
    if (sttStatus !== STT_STATUS.CONNECTING) {
      return false;
    }

    // ライセンストークンとアクセスキーの両方が保存されていない場合は何もしない(起こりえないが一応チェック)
    if (!hasAuthInfo()) {
      return false;
    }

    return true;
  }, [hasAuthInfo, sttStatus]);

  /**
   * 音声区間検出値が変更された場合の処理
   */
  const onChangeVadThreshold = useCallback(() => {
    // リトライ事前チェック
    const canRetryCheck: boolean = shouldRetryCheck();
    if (!canRetryCheck) {
      return;
    }

    // ライセンストークンの期限チェック
    if (!checkLicenseTokenExp(licenseTokenRef.current)) {
      requestRetryStart();

      return;
    }

    // Reduxに保存された「音声区間検出値」と一致する場合は何もしない
    if (currentVadThresholdRef.current === vadThreshold) {
      return;
    }

    requestRetryStart();
  }, [requestRetryStart, shouldRetryCheck, vadThreshold]);

  /**
   * 翻訳元言語・翻訳先言語が変更された
   */
  const onChangeLanguage = useCallback(async () => {
    // 翻訳先言語がTTS対応言語か判定し非対応言語ならTTS停止
    pauseTtsOnDestLang(destlang);

    // リトライ事前チェック
    const canRetryCheck: boolean = shouldRetryCheck();
    if (!canRetryCheck) {
      return;
    }

    // ライセンストークンの期限チェック
    if (!checkLicenseTokenExp(licenseTokenRef.current)) {
      requestRetryStart();

      return;
    }

    try {
      // Reduxに保存された「言語自動判別モード」と一致する場合は何もしない
      if (currentAutoDetectModeRef.current === autoDetectMode) {
        return;
      }

      // 言語設定API呼び出し
      const langApiResponse = await langApi({
        srclang,
        destlang,
      });
      if (langApiResponse.resultCode === LANG_API_RESULT_CODE.OK) {
        return;
      }

      requestRetryStart();
    } catch {
      requestRetryStart();
    }
  }, [
    autoDetectMode,
    destlang,
    pauseTtsOnDestLang,
    requestRetryStart,
    shouldRetryCheck,
    srclang,
  ]);

  /**
   * 翻訳元言語・翻訳先言語・[双方向に通訳]トグル(ON/OFF)が変更された
   */
  useEffect(() => {
    onChangeLanguage();

    // onChangeLanguageを監視対象外としたいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destlang, srclang, isInteractive]);

  /**
   * 共有画面の状態が「再開」に変更された場合の処理
   */
  const onChangeShareResumed = useCallback(() => {
    // ライセンストークンとアクセスキーの両方が保存されているか
    if (!hasAuthInfo()) {
      return;
    }

    // ライセンストークンの期限チェック
    if (!checkLicenseTokenExp(licenseTokenRef.current)) {
      // ライセンストークンとアクセスキーを削除
      resetAuthInfo();
    }

    // リトライを実行(共有再開時はリトライしないとFirestoreに書き込まれない)
    // 共有再開後はSTTを必ずONにする必要があるので、STT状態に関わらず必ずリトライする
    setRetryStatus(RETRY_STATUS.RETRY);
  }, [hasAuthInfo, resetAuthInfo, setRetryStatus]);

  /**
   * 共有画面の状態を監視
   */
  useEffect(() => {
    // カンファレンスモード時以外は何もしない
    if (!isConferenceMode) {
      return;
    }
    // 共有中以外は何もしない
    if (!isSharedStatus()) {
      return;
    }
    // 共有停止時は何もしない
    if (!isShareActive) {
      return;
    }
    // 共有再開時
    onChangeShareResumed();

    // changeShareStatusActiveを監視対象外としたいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShareActive]);

  /**
   * 音声区間検出値が変更された
   */
  useEffect(() => {
    onChangeVadThreshold();

    // onChangeVadThresholdを監視対象外としたいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vadThreshold]);

  /**
   * リトライ状態を監視
   */
  useEffect(() => {
    if (retryStatus !== RETRY_STATUS.RETRY) {
      // リトライのリクエスト時以外は何もしない
      return;
    }
    if (sttStatusRef.current === STT_STATUS.INACTIVE) {
      // STT実行前は何もしない
      return;
    }
    if (sttErrorTypeRef.current !== STT_ERROR_TYPE.NONE) {
      // Reduxで管理している「音声認識の失敗理由」が「なし」か確認
      // すでにエラーダイアログ表示中の場合は何もしない
      return;
    }

    // リトライ実行
    retryStream();
  }, [retryStatus, retryStream]);

  /**
   * 音声認識破棄
   */
  const discard = () => {
    // スクリプトプロセッサ停止
    stopAudioContext();
    // Websocket閉じる
    closeWebSocket();
    // Reduxに保存した「翻訳情報」をすべてリセット
    resetState();
    // 実行中のリトライをすべて破棄
    if (retryIDRef.current) {
      retryIDRef.current.forEach((id) => {
        retryAbortIDRef.current.add(id);
      });
    }
  };

  /**
   * マウント時、アンマウント時の処理
   */
  useEffect(() => {
    resetState();

    // アンマウント時はWebSocketを切断し、Reduxに保管していた翻訳関連の情報をリセット
    return () => {
      discard();
    };

    // コンポーネントのマウント/アンマウント時に1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    requestStartStream,
    sendAudio,
    sendFinal,
    closeStream,
    discard,
  };
};
