import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCSVDownload } from '@/features/history';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { HistoryData } from '@/states/slices/browserHistorySlice';

/**
 * ゲスト画面 翻訳結果(CSV)ダウンロード カスタムフック
 *
 * @returns
 */
export const useGuestTranslationDownload = () => {
  const { t } = useTranslation();
  const { recognizedList } = useTranslationInfo();
  const { convertDataToCommaSeparateStr, downloadCSVDataWithBom } =
    useCSVDownload();

  /**
   * ボディ部分のCSVデータ生成
   */
  const bodyCSVData = useMemo(() => {
    const historyList: HistoryData[] = [];
    const dataArray: string[][] = [];
    recognizedList.forEach((recognizedItem) => {
      historyList.push({
        通訳日時: recognizedItem.value.date,
        通訳元言語: t(`language.${recognizedItem.value.srclang}`),
        通訳元テキスト: recognizedItem.value.stt,
        通訳先言語: t(`language.${recognizedItem.value.destlang}`),
        通訳先テキスト: recognizedItem.value.ttt,
      });
    });

    historyList.forEach((historyData) => {
      dataArray.push([
        `${historyData.通訳日時}`,
        `${historyData.通訳元言語}`,
        `${historyData.通訳元テキスト}`,
        `${historyData.通訳先言語}`,
        `${historyData.通訳先テキスト}`,
      ]);
    });

    // 配列の中身を取り出してカンマ区切りにする
    return convertDataToCommaSeparateStr(dataArray);
  }, [convertDataToCommaSeparateStr, recognizedList, t]);

  /**
   * CSVダウンロードボタンを押した時の処理
   */
  const onCsvDownloadButton = useCallback(() => {
    downloadCSVDataWithBom(bodyCSVData);
  }, [bodyCSVData, downloadCSVDataWithBom]);

  return {
    onCsvDownloadButton,
  };
};
