import { useCallback } from 'react';

import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';

import { useBrowserAudioSetting } from './useBrowserAudioSetting';
import { useBrowserHistory } from './useBrowserHistory';
import { useBrowserTranslationDisplay } from './useBrowserTranslationDisplay';
import { useBrowserUILanguage } from './useBrowserUILanguage';
import { useBrowserUserInfo } from './useBrowserUserInfo';

/**
 * ローカルストレージ削除用 hooks
 */
export const useClearLocalStorage = () => {
  const { resetState: resetLanguage } = useBrowserLanguage();
  const { resetHistoryListState } = useBrowserHistory();
  const { resetState: resetTranslationDisplay } =
    useBrowserTranslationDisplay();
  const { resetState: resetBrowserUserInfo } = useBrowserUserInfo();
  const { resetState: resetAudioSetting } = useBrowserAudioSetting();
  const { resetState: resetUILanguage } = useBrowserUILanguage();

  /**
   * ローカルストレージ削除を実行する
   */
  const doCleanLocalStorage = useCallback(() => {
    resetLanguage();
    resetHistoryListState();
    resetTranslationDisplay();
    resetBrowserUserInfo();
    resetAudioSetting();
    resetUILanguage();
  }, [
    resetBrowserUserInfo,
    resetHistoryListState,
    resetLanguage,
    resetTranslationDisplay,
    resetAudioSetting,
    resetUILanguage,
  ]);

  return {
    doCleanLocalStorage,
  };
};
