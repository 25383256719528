/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useEffect, useRef, useState } from 'react';

import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';
import { isMobile } from '@/utils/device';

// スクロール終了判定までの時間(ミリ秒)
const SCROLL_END_TIMEOUT = 500;

/**
 * 上方向にスクロール時に下部に暫定テキストを固定表示する必要があるか否か
 *
 * @param recognizedListElement 翻訳リスト要素
 *
 * true=固定表示が必要
 */
const needsFixedInterimView = (
  recognizedListElement: Element | null,
): boolean => {
  if (!recognizedListElement) {
    return false;
  }
  const liElement = recognizedListElement.lastChild as Element;

  if (liElement === null) {
    // TTTテキストが1件も画面に表示されていない場合はreturn
    return false;
  }

  // 見えているビューの高さから半分移動したら表示対象とする
  // スクロールできるビュー全体の高さ - (見えているビュー + 見えているビューの半分)
  // 現時点のスクロールトップが上記で算出した位置を下回った場合に表示
  const isInterim =
    Math.ceil(recognizedListElement.scrollTop) <
    Math.ceil(recognizedListElement.scrollHeight) -
      Math.ceil(recognizedListElement.clientHeight) -
      Math.ceil(recognizedListElement.clientHeight) / 2;

  return isInterim;
};

/**
 * 翻訳画面のリストビューの自動スクロール制御用カスタムフック
 *
 * ※スクロールが一番下にある場合のみ自動スクロールする
 * @returns
 */
export const useRecognizedListScroll = () => {
  const recognizedListRef = useRef<HTMLUListElement>(null);

  // スクロールが表示された後1回目の判定かどうか(true=1回目)
  const isFirstTimeScrollAfterVisibleScrollRef = useRef<boolean>(true);

  // フッター表示、スクロール中かどうか
  const { setFooterDisplay, isDisplayFooter, setIsScrollingTranslation } =
    useTranslationDisplay();

  // フッターの表示状態Ref
  const isDisplayFooterRef = useRef<boolean>(isDisplayFooter);
  useEffect(() => {
    isDisplayFooterRef.current = isDisplayFooter;
  }, [isDisplayFooter]);

  // フッター表示時に自動スクロールが必要かどうかRef
  const needsAutoScrollWhenFooterOpenRef = useRef<boolean>(false);
  // フッター表示時に自動スクロール中かどうか
  const isAutoScrollWhenFooterOpenRef = useRef<boolean>(false);

  const [isDisplayInterim, setIsDisplayInterim] = useState<boolean>(false);

  // スクロール判定用タイマーID
  const scrollTimeoutIDRef = useRef<NodeJS.Timeout | undefined>(undefined);

  /**
   * スクロールが表示されているか否か
   *
   * @param ulElement ul要素
   * @returns true=スクロールがある
   */
  const isScrollVisible = (ulElement: HTMLUListElement): boolean => {
    if (
      Math.ceil(ulElement.scrollTop) + Math.ceil(ulElement.offsetHeight) <
      Math.ceil(ulElement.scrollHeight)
    ) {
      return true;
    }

    if (Math.ceil(ulElement.scrollHeight) > Math.ceil(ulElement.clientHeight)) {
      return true;
    }

    return false;
  };

  /**
   * スクロールバーが一番下にあるか否か
   * 最新の翻訳テキストの表示が先にされるため、その分の高さをマイナスして比較する
   *
   * @param ulElement ul要素
   * @param liElement li要素
   * @returns true=番下にスクロールバーがある
   */
  const isScrollBarBottom = (
    ulElement: HTMLUListElement,
    liElement: Element,
  ) => {
    const scrollBar =
      Math.ceil(ulElement.scrollTop) +
      Math.ceil(ulElement.offsetHeight) -
      (Math.ceil(ulElement.scrollHeight) - Math.ceil(liElement.clientHeight));

    // Math.ceil()で小数点切り上げられる
    // Chrome Bookで(scrollTop + offsetHeight) - (scrollHeight - clientHeight) = -1となる場合がある
    // 以上より-1以上であれば一番下にスクロールバーがあるとみなして自動スクロール
    if (scrollBar >= -1) {
      return true;
    }

    return false;
  };

  /**
   * リストビューのスクロール制御
   */
  const scrollListView = useCallback(() => {
    if (!recognizedListRef.current) {
      return;
    }

    const view: HTMLUListElement = recognizedListRef.current;
    const viewLastChild = view.lastChild as Element;
    const elements =
      view.childNodes as unknown as HTMLCollectionOf<HTMLLIElement>;

    if (viewLastChild === null || elements === undefined) {
      // TTTテキストが1件も画面に表示されていない場合はreturn
      return;
    }

    if (!isScrollVisible(view)) {
      // スクロールが表示されていない場合は自動スクロール不要なのでreturn
      return;
    }

    if (
      !isScrollBarBottom(view, viewLastChild) &&
      !isFirstTimeScrollAfterVisibleScrollRef.current
    ) {
      // スクロールバーが一番下にないかつスクロールバー表示直後でない場合は自動スクロール不要なのでreturn
      return;
    }

    if (isFirstTimeScrollAfterVisibleScrollRef.current) {
      isFirstTimeScrollAfterVisibleScrollRef.current = false;
    }

    const len = elements.length;
    elements[len - 1].scrollIntoView({
      block: 'start',
      inline: 'center',
    });
  }, []);

  /**
   * スクロール時にフッターのクローズが必要かどうか
   */
  const needsFooterClose = useCallback((view: HTMLUListElement | null) => {
    if (view === null) {
      return false;
    }

    const viewLastChild = view.lastChild as Element;
    const elements =
      view.childNodes as unknown as HTMLCollectionOf<HTMLLIElement>;

    if (viewLastChild === null || elements === undefined) {
      // TTTテキストが1件も画面に表示されていない場合はreturn
      return false;
    }

    if (!isScrollVisible(view)) {
      // スクロールが表示されていない場合は不要なのでreturn
      return false;
    }

    if (isFirstTimeScrollAfterVisibleScrollRef.current) {
      // スクロールバー表示直後は不要なのでreturn
      return false;
    }

    if (isAutoScrollWhenFooterOpenRef.current) {
      // フッター表示時の自動スクロール中は不要なのでreturn
      return false;
    }

    // スクロールが一番下にある場合スクロールイベントの検知不要なのでreturn
    if (isScrollBarBottom(view, viewLastChild) && isDisplayFooterRef.current) {
      return false;
    }

    return true;
  }, []);

  /**
   * 暫定テキストを固定表示するか否かの制御
   */
  const fixedInterimView = useCallback((): void => {
    const isFixed = needsFixedInterimView(recognizedListRef.current);

    setIsDisplayInterim(isFixed);
  }, []);

  /**
   * スクロールイベント検知時処理
   *
   * ・スクロール可能時にフッターを非表示にする
   * ・文字スライダー、入力元デバイスドロップダウンを表示中はフッターを非表示にしない
   */
  useEffect(() => {
    const view: HTMLUListElement | null = recognizedListRef.current;

    /**
     * イベント登録
     */
    const addEvent = () => {
      if (!isMobile()) {
        view?.addEventListener('scroll', scrollEvent); // PC用
      } else {
        view?.addEventListener('touchmove', scrollEvent); // スマホ用
      }
    };

    /**
     * イベント削除
     */
    const removeEvent = () => {
      if (!isMobile()) {
        view?.removeEventListener('scroll', scrollEvent);
      } else {
        view?.removeEventListener('touchmove', scrollEvent);
      }
    };

    /**
     * スクロール終了時の処理
     */
    const scrollEnd = () => {
      // スクロール終了
      setIsScrollingTranslation(false);
      // フッター表示時の自動スクロール終了
      isAutoScrollWhenFooterOpenRef.current = false;

      // フッターがCLOSEしている時、スクロールバーが一番下にあるかどうかを判定して保存する
      if (!recognizedListRef.current) {
        return;
      }
      if (isDisplayFooterRef.current) {
        return;
      }
      const recognizedListView: HTMLUListElement = recognizedListRef.current;
      if (!isScrollVisible(recognizedListView)) {
        return;
      }
      const viewLastChild = recognizedListView.lastChild as Element;
      needsAutoScrollWhenFooterOpenRef.current = isScrollBarBottom(
        recognizedListView,
        viewLastChild,
      );
    };

    /**
     * スクロール時の処理
     */
    const scrollEvent = () => {
      if (needsFooterClose(view)) {
        // フッター非表示
        setFooterDisplay(false);
        setIsScrollingTranslation(true);
      }

      // スクロールを停止して500ms後に終了とする
      clearTimeout(scrollTimeoutIDRef.current);

      scrollTimeoutIDRef.current = setTimeout(() => {
        scrollEnd();
      }, SCROLL_END_TIMEOUT);

      // 固定暫定テキストを表示するか否かをスクロールのたびに検知するために実行する
      // scrollEvent内で実行しないとリアルタイムで固定表示されないため
      fixedInterimView();
    };

    // このカスタムフックがマウントされたときに実行される
    addEvent();

    // アンマウント時に実行される
    return () => {
      removeEvent();
    };

    // マウント/アンマウント時の処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * フッター表示/非表示アニメーション終了検知時の処理
   */
  useEffect(() => {
    /**
     * アニメーション終了時イベント
     */
    const animationEndEvent = () => {
      if (!recognizedListRef.current || !isDisplayFooterRef.current) {
        // フッターが閉じている時は何もしない
        return;
      }

      if (!isDisplayFooterRef.current) {
        // フッターが閉じている時は何もしない
        return;
      }

      // フッター表示状態かつアニメーション開始時点でスクロールバーが一番下にある場合自動で一番下までスクロール
      if (!needsAutoScrollWhenFooterOpenRef.current) {
        return;
      }
      isAutoScrollWhenFooterOpenRef.current = true; // ヘッダー/フッター表示時に下方向に自動スクロール中

      const recognizedListView: HTMLUListElement = recognizedListRef.current;
      const liElements =
        recognizedListView.childNodes as unknown as HTMLCollectionOf<HTMLLIElement>;

      // 一番下までスクロール
      const len = liElements.length;
      liElements[len - 1].scrollIntoView({
        block: 'start',
        inline: 'center',
      });
    };

    // アニメーションの位置を取るためのTranslationListViewの一番上のdiv要素を取得
    const transitionListContentRef =
      recognizedListRef.current?.parentNode?.parentNode?.parentNode;

    // このカスタムフックがマウントされたときに実行される
    transitionListContentRef?.addEventListener(
      'transitionend',
      animationEndEvent,
    );

    // このカスタムフックがアンマウントされたときに実行される
    return () => {
      transitionListContentRef?.removeEventListener(
        'transitionend',
        animationEndEvent,
      );
    };

    // マウント/アンマウント時の処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    recognizedListRef,
    scrollListView,
    fixedInterimView,
    isDisplayInterim,
  };
};
