import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import { VIEW_CONFIG_STATUS } from '@/constants/firestore';
import {
  useViewUpdateApi,
  VIEW_UPDATE_API_RESULT_CODE,
  ViewUpdateApiRequest,
  ViewUpdateApiResponse,
} from '@/features/api';
import { useNeedAgreement } from '@/features/expired';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';
import { useToastInfo } from '@/hooks/useToastInfo';

/**
 * プロパティ
 */
type Props = {
  // 共有停止確認ダイアログのクローズ処理
  onCloseSharePauseConfirmDialog: () => void;
};

/**
 * 共有停止確認ダイアログ用 hooks
 *
 * @returns
 */
export const useSharePauseConfirmDialog = ({
  onCloseSharePauseConfirmDialog,
}: Props) => {
  const { addToastMessage } = useToastInfo();
  const { t } = useTranslation();
  const { updateView, viewUpdateState, resetViewUpdateState } =
    useViewUpdateApi();
  const { needAgreement } = useNeedAgreement();
  const { setCommonErrorDialogType } = useCommonErrorDialog();

  /**
   * 一時停止ボタン押下時の処理
   */
  const clickPauseButton = useCallback(() => {
    const request: ViewUpdateApiRequest = {
      status: VIEW_CONFIG_STATUS.INACTIVE,
    };
    // 共有画面更新API呼び出し
    updateView(request);
  }, [updateView]);

  /**
   * 共有停止確認ダイアログを閉じる処理を実行
   */
  const handleCloseSharePauseConfirmDialog = useCallback(() => {
    onCloseSharePauseConfirmDialog();
    // 共有画面情報更新APIの呼び出し状況をリセット
    // 共有停止/再開ボタンが配置されているヘッダーが常に表示されているので明示的にリセットする必要がある
    resetViewUpdateState();
  }, [onCloseSharePauseConfirmDialog, resetViewUpdateState]);

  /**
   * 共有画面更新APIの成功時の処理
   */
  const updateViewSuccess = useCallback(() => {
    // 共有停止確認ダイアログを閉じる
    // Firestore上の共有の一時停止/再開の変更を検知したタイミングでReduxを更新するので、ここでは値は更新しない
    handleCloseSharePauseConfirmDialog();
  }, [handleCloseSharePauseConfirmDialog]);

  /**
   * 共有画面更新APIの失敗時の処理
   */
  const updateViewFailed = useCallback(
    (apiResponse: ViewUpdateApiResponse | undefined) => {
      // 共有停止確認ダイアログを閉じる
      handleCloseSharePauseConfirmDialog();
      // アクセス期限切れエラー
      if (apiResponse?.resultCode === VIEW_UPDATE_API_RESULT_CODE.WARN_AUTH) {
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR);

        return;
      }

      // PTID期限切れエラー
      if (
        apiResponse?.resultCode ===
        VIEW_UPDATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
      ) {
        setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.PTID_ERROR);

        return;
      }

      // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
      if (
        apiResponse?.resultCode ===
        VIEW_UPDATE_API_RESULT_CODE.INFO_NEED_AGREEMENT
      ) {
        needAgreement();

        return;
      }

      addToastMessage(
        t('sharePauseConfirmDialog.共有の停止に失敗しました'),
        TOAST_ICON_TYPE.WARNING,
      );
    },
    [
      addToastMessage,
      handleCloseSharePauseConfirmDialog,
      needAgreement,
      setCommonErrorDialogType,
      t,
    ],
  );

  /**
   * 共有画面更新APIの呼び出し状況を監視
   */
  useEffect(() => {
    if (
      viewUpdateState.status === API_STATUS.IDLE ||
      viewUpdateState.status === API_STATUS.LOADING
    ) {
      return;
    }

    // 成功
    if (
      viewUpdateState.status === API_STATUS.SUCCESS &&
      viewUpdateState.data?.resultCode === VIEW_UPDATE_API_RESULT_CODE.OK
    ) {
      updateViewSuccess();

      return;
    }

    updateViewFailed(viewUpdateState.data);
  }, [updateViewFailed, updateViewSuccess, viewUpdateState]);

  return {
    clickPauseButton,
    viewUpdateState,
  };
};
