import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShareDialogForHost } from '@/features/share';
import useOpen from '@/hooks/useOpen';

import styles from './ShareButton.module.scss';

/**
 * プロパティ
 */
type Props = {
  // 共有URL
  shareURLKey: string;
};

/**
 * 共有中バナーボタン コンポーネント
 *
 * @returns
 */
export const ShareButton = React.memo(({ shareURLKey }: Props) => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.share}>
        <button
          className={`${styles['share-button']} ${'header-01'}`}
          onClick={(e) => {
            onOpen();
            e.stopPropagation();
          }}
          tabIndex={-1}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <span className={styles['share-icon']} />
          <div className={styles['share-button-container']}>
            {t('shareButton.共有設定')}
          </div>
        </button>
      </div>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && (
        <ShareDialogForHost
          isOpen
          onClose={onClose}
          shareURLKey={shareURLKey}
        />
      )}
    </>
  );
});
