import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';
import { SerialMode } from '@/states/slices/browserUserInfoSlice';

/**
 * リクエストパラメータ
 */
export type SerialAuthApiRequest = {
  // シリアル
  serial: string;
};

/**
 * 結果コード
 */
export const SERIAL_AUTH_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // ライセンス登録されていない
  INFO_INVALID_LICENSE: 'INFO_INVALID_LICENSE',
  // ライセンス期限切れ
  INFO_EXPIRED_LICENSE: 'INFO_EXPIRED_LICENSE',
  // 外部API呼び出し失敗
  WARN_FAILED_CALL: 'WARN_FAILED_CALL',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;

export type SerialAuthApiResultCode =
  (typeof SERIAL_AUTH_API_RESULT_CODE)[keyof typeof SERIAL_AUTH_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isSerialAuthApiResultCode = (
  resultCode: string,
): resultCode is SerialAuthApiResultCode => {
  if (resultCode in SERIAL_AUTH_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type SerialAuthApiResponse = {
  // 結果コード
  resultCode: SerialAuthApiResultCode;
  // シリアルモード
  mode: SerialMode;
};

/**
 * API 呼び出し
 * @param request
 * @returns
 */
const callApi = (
  request: SerialAuthApiRequest,
): Promise<SerialAuthApiResponse> => {
  // リクエスト送信（POST）
  const response = browserAxios
    .post<SerialAuthApiResponse>(BROWSER.API_URL.SERIAL_AUTH, request, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * シリアル認証API リクエスト
 * @param request
 * @returns
 */
export const serialAuthApi = (
  request: SerialAuthApiRequest,
): Promise<SerialAuthApiResponse> => callApi(request);
