import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  browserUserInfoSlice,
  BrowserUserInfoState,
  SERIAL_MODE,
  SerialMode,
} from '@/states/slices/browserUserInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * ログインユーザ情報をローカルストレージに保存 hooks
 *
 * @returns
 */
export const useBrowserUserInfo = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setMode, clearUserInfo } = browserUserInfoSlice.actions;

  const { mode } = useSelector<RootState, BrowserUserInfoState>(
    (state) => state.browserUserInfo,
  );

  /**
   * カンファレンス用シリアルでログイン中か否か
   *
   * @returns true=カンファレンス用シリアルでログイン / false=その他でログイン
   */
  const isConferenceMode = useMemo(
    () => mode === SERIAL_MODE.CONFERENCE,
    [mode],
  );

  /**
   * モードを更新
   */
  const changeMode = useCallback(
    (value: SerialMode) => {
      dispatch(setMode(value));
    },
    [dispatch, setMode],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearUserInfo());
  }, [dispatch, clearUserInfo]);

  return {
    // シリアルのモード
    mode,
    // カンファレンス用シリアルでログイン中か否か
    isConferenceMode,
    // モードを更新
    setMode: changeMode,
    // 全てのStateをリセット
    resetState,
  };
};
