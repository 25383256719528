import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * State
 */
export type ShareViewEndInfoState = {
  // 共有終了ダイアログを表示するか否か
  isOpenShareInvalidDialog: boolean;
};

/**
 * 初期State
 */
const initialState: ShareViewEndInfoState = {
  isOpenShareInvalidDialog: false,
};

/**
 * 共有画面情報(終了時用) Slice
 */
export const shareViewEndInfoSlice = createSlice({
  name: 'shareViewEndInfo',
  initialState,
  reducers: {
    // 共有終了ダイアログを表示するか否かの判定値を更新
    setIsOpenShareInvalidDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareInvalidDialog = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
