import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import {
  CREATE_VIEW_API_RESULT_CODE,
  CreateViewApiResponse,
  useCreateViewApi,
} from '@/features/api';
import { useNeedAgreement } from '@/features/expired';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { useToastInfo } from '@/hooks/useToastInfo';

/**
 * プロパティ
 */
type Props = {
  // 共有開始確認ダイアログのクローズ処理
  onCloseShareStartDialog: () => void;
};

/**
 * 共有開始確認ダイアログ用 hooks
 *
 * @returns
 */
export const useShareStartDialog = ({ onCloseShareStartDialog }: Props) => {
  const { updateShareViewInfoOnCreateViewApiResponse } = useShareViewInfo();
  const { createView, createViewState } = useCreateViewApi();
  const { addToastMessage } = useToastInfo();
  const { needAgreement } = useNeedAgreement();
  const { setCommonErrorDialogType } = useCommonErrorDialog();
  const { t } = useTranslation();

  // プログレスサークル表示フラグ
  const [isShowProgress, setIsShowProgress] = useState<boolean>(false);

  /**
   * 開始ボタン押下時の処理
   */
  const clickStartButton = useCallback(() => {
    // 共有画面作成API呼び出し
    createView();
  }, [createView]);

  /**
   * 共有画面作成APIの成功時の処理
   */
  const createViewSuccess = useCallback(
    (apiResponse: CreateViewApiResponse) => {
      updateShareViewInfoOnCreateViewApiResponse(apiResponse).then(() => {
        // 共有開始成功後に共有開始確認ダイアログを閉じる
        onCloseShareStartDialog();
        // プログレスサークル非表示
        setIsShowProgress(false);
      });
    },
    [onCloseShareStartDialog, updateShareViewInfoOnCreateViewApiResponse],
  );

  /**
   * 共有画面作成APIの失敗時の処理
   */
  const createViewFailed = useCallback(
    (apiResponse: CreateViewApiResponse | undefined) => {
      try {
        // アクセス期限切れエラー
        if (apiResponse?.resultCode === CREATE_VIEW_API_RESULT_CODE.WARN_AUTH) {
          setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR);

          return;
        }

        // PTID期限切れエラー
        if (
          apiResponse?.resultCode ===
          CREATE_VIEW_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
        ) {
          setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.PTID_ERROR);

          return;
        }

        // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
        if (
          apiResponse?.resultCode ===
          CREATE_VIEW_API_RESULT_CODE.INFO_NEED_AGREEMENT
        ) {
          needAgreement();

          return;
        }

        // その他エラー
        addToastMessage(
          t('shareStartDialog.共有開始に失敗しました'),
          TOAST_ICON_TYPE.WARNING,
        );
      } finally {
        // 共有開始確認ダイアログをクローズ
        onCloseShareStartDialog();
        // プログレスサークル非表示
        setIsShowProgress(false);
      }
    },
    [
      addToastMessage,
      needAgreement,
      onCloseShareStartDialog,
      setCommonErrorDialogType,
      t,
    ],
  );

  /**
   * 共有画面作成APIの呼び出し状況を監視
   */
  useEffect(() => {
    if (createViewState.status === API_STATUS.IDLE) {
      return;
    }

    if (createViewState.status === API_STATUS.LOADING) {
      // プログレスサークル表示
      setIsShowProgress(true);

      return;
    }

    // 成功
    if (
      createViewState.status === API_STATUS.SUCCESS &&
      createViewState.data?.resultCode === CREATE_VIEW_API_RESULT_CODE.OK
    ) {
      createViewSuccess(createViewState.data);

      return;
    }

    // 失敗
    createViewFailed(createViewState.data);
  }, [createViewFailed, createViewState, createViewSuccess]);

  return {
    // 開始ボタン押下時の処理
    clickStartButton,
    // プログレスサークルを表示するかどうか
    isShowProgress,
  };
};
