import React from 'react';
import { useTranslation } from 'react-i18next';

import screenShare from '@/assets/screen_share.svg';
import useOpen from '@/hooks/useOpen';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';

import { ShareDialogForHost } from '../../../share/components/ShareDialogForHost';

/**
 * プロパティ
 */
type Props = {
  onCloseMenu: () => void;
};
/**
 * ヘッダー > メインメニュー > 通訳を共有するメニュー
 */
export const ShareInfoContainer = React.memo(({ onCloseMenu }: Props) => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();
  const { shareURLKey } = useShareViewInfo();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
        className="main-menu-02"
      >
        <img src={screenShare} alt="screenShare" />
        {t('mainMenu.共有URLを表示/終了')}
      </button>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && (
        <ShareDialogForHost
          isOpen
          onClose={onClose}
          shareURLKey={shareURLKey}
        />
      )}
    </>
  );
});
