import { useMemo } from 'react';

import {
  FEEDBACK_LINK_URL_EN,
  FEEDBACK_LINK_URL_JA,
  LOCALE_LANG,
} from '@/constants';
import { useBrowserUILanguage } from '@/hooks/useBrowserUILanguage';

/**
 * メインメニュー > フィードバックメニュー用 hooks
 *
 * @returns
 */
export const useFeedbackContainer = () => {
  const { language } = useBrowserUILanguage();

  /**
   * フィードバックリンク
   * UI言語ごとに変える
   */
  const feedbackLink = useMemo(() => {
    switch (language) {
      case LOCALE_LANG.JA:
        return FEEDBACK_LINK_URL_JA;
      default:
        return FEEDBACK_LINK_URL_EN;
    }
  }, [language]);

  return {
    feedbackLink,
  };
};
