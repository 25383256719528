// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useLocation } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import { LanguageAreaContainer } from '@/features/selectlanguage';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';

import { useBack } from '../hooks/useBack';
import { useCampaignBanner } from '../hooks/useCampaignBanner';
import { useMenuValidator } from '../hooks/useMenuValidator';
import { useTranslationPath } from '../hooks/useTranslationPath';

import campaignStyles from './CampaignBanner.module.scss';
import styles from './Header.module.scss';
import { MainMenuContainer } from './menu/MainMenuContainer';
import { ShareButton } from './sharebutton/ShareButton';
import { SharePauseResumeButton } from './sharebutton/SharePauseResumeButton';

/**
 * ヘッダー 子コンポーネント
 *
 * @returns
 */
const HeaderView = React.memo(() => {
  const { pathname, state } = useLocation();
  const { isTranslationPath, isDisplayBack } = useTranslationPath();
  const { onClickBackButton } = useBack();
  const { isMenuHidden } = useMenuValidator();
  const { shouldShowCampaignBanner, onClickCampaignBanner } =
    useCampaignBanner();
  const { isConferenceMode } = useBrowserUserInfo();
  const { shareURLKey, isShared } = useShareViewInfo();

  return (
    <header data-testid="header">
      <div
        className={styles['header-contents']}
        role="button"
        tabIndex={-1}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className={styles['header-left-contents']}>
          <div className={styles['header-logo']}>
            {isDisplayBack(pathname, state) ? (
              <button
                type="button"
                onClick={() => {
                  onClickBackButton(pathname);
                }}
                className={styles['back-btn']}
              />
            ) : (
              <div>
                <img
                  className={styles['header-logo-img']}
                  src={logo}
                  alt="Workflow"
                  decoding="async"
                />
              </div>
            )}
            {!isMenuHidden && shouldShowCampaignBanner && (
              <button
                type="button"
                onClick={onClickCampaignBanner}
                className={`${campaignStyles['campaign-banner']} dts-campaign`}
              />
            )}
          </div>
        </div>
        <div className={styles['header-center-contents']}>
          {/* 言語選択エリア(翻訳画面のみ表示) */}
          {isTranslationPath(pathname) ? <LanguageAreaContainer /> : ''}
        </div>
        <div className={styles['header-right-contents']}>
          {/* 共有に関するボタンを表示 */}
          {isShared && isConferenceMode && isTranslationPath(pathname) && (
            <div className={styles['share-contents']}>
              <SharePauseResumeButton />
              <ShareButton shareURLKey={shareURLKey} />
            </div>
          )}
          {/* メインメニュー */}
          {!isMenuHidden ? <MainMenuContainer /> : ''}
        </div>
      </div>
    </header>
  );
});

/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
export const Header = React.memo(() => <HeaderView />);
